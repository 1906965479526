/*!

 File: set-data-tiles.js
 Author: Ember
 Version: 1.0.6 - [CCS-637] - data-extra on delete
 JS Dependencies:
    'closest' - polyfill
    helper-trigger-event

 Description:
    Functionality for adding / removing data tiles
*/

// this is so it can be passed as a callback through HTML
var addDataTileCB;

var setDataTiles = {
    init: function (selector) {

        var me = setDataTiles;

        addDataTileCB = me.addDataTile;
        
        var delEvt = function(e){

            var elTarget = e.target;

            // Closest captures self as well a parents
            var elDelBtn = elTarget.closest('.js-data-tile-delete')
            if (!elDelBtn) return;

            // Button clicked
            e.preventDefault();

            me.deleteTile(elDelBtn)

        }

        // Attach delete event
        on('click', document, delEvt);

        // Check totals
        var elContainers = document.querySelectorAll('.js-data-tiles');

        if(elContainers && elContainers.length>0){
            forEachNode(elContainers, function (i, el) {
                me.checkTotals(el);
            });
        }

    },
    addDataTile: function(el, data){
        
        var me = setDataTiles;

        if(el){
            var elContainer = el.closest('.js-data-tiles');
            var elDataTilesList = elContainer.querySelector('.js-data-tiles-list');

            if (elDataTilesList){

                // Check is array
                if(!data.entities || !Array.isArray(data.entities)){
                    console.log('set-data-tiles.js is expecting an entities array response');
                    return;
                }

                //Loop through entities
                data.entities.forEach(function (entity) {
                    // place any returned markup
                    if(entity.html && entity.html != ''){
                        var replace = false;
                        var element = entity.html.indexOf('<tr') !== -1 ? 'tbody' : 'div';
                        var elNewContainer = document.createElement(element);
                        elNewContainer.innerHTML = entity.html;
                        var elNewInner = elNewContainer.firstElementChild;
                        // Get ID of new tile
                        var newId = elNewInner.getAttribute('data-id');
                        
                        if(newId && newId != ''){
                            var elExisting = elDataTilesList.querySelector('.js-data-tiles-item[data-id="' + newId + '"]');
                            if(elExisting){
                                replace = true;
                                elDataTilesList.replaceChild(elNewInner, elExisting);
                            }
                        }
                        // Add to end of list
                        if(!replace){
                            elDataTilesList.appendChild(elNewInner);
                        }
                    }
                });

                // Check totals
                me.checkTotals(elContainer);

                // Is there a datatiles callback?
                var callback = elContainer.getAttribute('data-dt-callback');

                // Run callback
                if (typeof window[callback] === 'function') {
                    window[callback](elContainer, data);
                }
                
                /*if(data.html && data.html != ''){
                    var replace = false;
                    var elNewContainer = document.createElement('div');
                    elNewContainer.innerHTML = data.html;
                    var elNewInner = elNewContainer.firstElementChild;
                    // Get ID of new tile
                    var newId = elNewInner.getAttribute('data-id');
                    if(newId && newId != ''){
                        var elExisting = elDataTilesList.querySelector('.js-data-tiles-item[data-id="' + newId + '"]');
                        if(elExisting){
                            replace = true;
                            elDataTilesList.replaceChild(elNewInner, elExisting);
                        }
                    }
                    // Add to end of list
                    if(!replace){
                        elDataTilesList.appendChild(elNewInner);
                    }
                    // Check totals
                    me.checkTotals(elContainer);
                }*/
               
            }else{
                console.log('set-data-tiles : missing .js-data-tiles-list')
            }
        }
    },
    deleteTile: function (elDelBtn) {
        var me = setDataTiles;

        //pendingButton.show(elDelBtn);

        var elTile = elDelBtn.closest('.js-data-tiles-item');
        var elContainer = elDelBtn.closest('.js-data-tiles');

        var updateUrl = elDelBtn.getAttribute('data-del-url');
        if(!updateUrl && elContainer){
            updateUrl = elContainer.getAttribute('data-del-url');
        }

        var confirmTitle = elDelBtn.getAttribute('data-del-confirm-title');
        if(!confirmTitle && elContainer){
            confirmTitle = elContainer.getAttribute('data-del-confirm-title');
        }
        if(!confirmTitle){
            confirmTitle = "Delete Item";
        }

        var confirmMessage = elDelBtn.getAttribute('data-del-confirm-message');
        if(!confirmMessage && elContainer){
            confirmMessage = elContainer.getAttribute('data-del-confirm-message');
        }
        if(!confirmMessage){
            confirmMessage = "Are you sure you want to delete this item?";
        }
    
        var sendRequest = function(){

            elTile.classList.add('disabled');
            elTile.offsetHeight; // force reflow
            elTile.classList.add('disabled-fade');

            // Param object
            var params = {};

            // Element ID
            // If data attr exists and isn't empty
            if (checkDataAttr(elTile, 'data-id')) {
                params['id'] = elTile.getAttribute('data-id');
            }

            // Add any extra data to Params
            var extraData = elDelBtn.getAttribute('data-extra');
            if(extraData && extraData != ''){
                extraData = JSON.parse(extraData);
                for (var i in extraData) {
                    params[i] = extraData[i];
                }
            }

            // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
            var config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }

            // Default error message
            var errorMessage = 'Sorry, there was a problem with your request.';

            // AJAX request
            axios.post(updateUrl, params, config)
                .then(function (response) {
                    var responseJson = response.data;

                    if(responseJson.status=='success'){

                        // Remove tile
                        elTile.classList.add('fade-out');
                        setTimeout(function(){
                            elTile.parentNode.removeChild(elTile);
                            // Check totals
                            me.checkTotals(elContainer);
                        }, 400);

                        // Show notification
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            Notify({
                                text: responseJson.message.text,
                                urgency: 'success',
                                offset: 100
                            }).showNotify();
                        }

                        // Process any domUpdate content
                        if(responseJson.updates && responseJson.updates.length){
                            responseJson.updates.forEach(function (item) {
                                var elUpdate = document.querySelector('#' + item.id);
                                if(elUpdate){
                                    elUpdate.innerHTML = item.html;
                                }
                            });
                        }

                    }

                    // Returned Error
                    if(responseJson.status=='error'){

                        // See if a message was returned
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            errorMessage = responseJson.message.text;
                        }

                        // Show error notification
                        Notify({
                            text: errorMessage,
                            status: 'error',
                            offset: 100
                        }).showNotify();

                        // enable button
                        //pendingButton.hide(elDelBtn);
                    }
                })
                .catch(function (error) {

                    if(error.response){
                        errorMessage  += '<br>' + error.response;
                    }

                    // Show error notification
                    Notify({
                        text: errorMessage,
                        status: 'error',
                        offset: 100
                    }).showNotify();

                    // enable button
                    //pendingButton.hide(elDelBtn);

                });

        }

        if(elTile && updateUrl){
            openMpDialog(confirmTitle, confirmMessage, function(){
                // Make request after close
                setTimeout(function(){
                    sendRequest();
                },400);
            },function(){
                //pendingButton.hide(elDelBtn);
            });
        }

    },
    checkTotals: function(elContainer){
        var maxTotal = elContainer.getAttribute('data-items-max');
        var elDataTilesList = elContainer.querySelector('.js-data-tiles-list');
        var elAddBtn = elContainer.querySelector('.js-data-tiles-add-btn');
        var elWarning = elContainer.querySelector('.js-data-tiles-warning');
        //
        var elTotal = elDataTilesList.querySelectorAll('.js-data-tiles-item');
        var total = elTotal.length;
        //
        
        if(total<1){
            elContainer.classList.add('hide-data-tiles-list');
        }else{
            elContainer.classList.remove('hide-data-tiles-list');
        }
        //
        if(maxTotal && maxTotal != ''){
            maxTotal = parseInt(maxTotal);
            
            
            if(elAddBtn){
                if(total >= maxTotal){
                    // disable button
                    elAddBtn.classList.add('disabled');
                    elAddBtn.disabled = true;
                    // Blur
                    triggerEvent(elAddBtn,'blur');
                    triggerEvent(elAddBtn,'mouseleave');
                    // warning
                    if(elWarning){
                        elWarning.classList.remove('hidden');
                    }
                }else{
                    // enable button
                    elAddBtn.classList.remove('disabled');
                    elAddBtn.disabled = false;
                    if(elWarning){
                        elWarning.classList.add('hidden');
                    }
                }
            }

        }
    }
}

