/*!

 File: set-notify.js
 Author: Ember
 Version: 1.0.3 [MS-509]
 JS Dependencies:


 Description:
    Custom vanilla js module / plugin based on toastr and Notify

*/

;(function (root, factory) {
    if ( typeof define === 'function' && define.amd ) {
        define([], function () {
			return factory(root);
		});
	} else if ( typeof exports === 'object' ) {
		module.exports = factory(root);
	} else {
		root.Notify = factory(root);
	}
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function (window) {

	'use strict';

    var Notify = function(options) {
        // Returning a new init object
        return new Notify.lib.init(options);
    }

    // Defining the prototype of the object
    Notify.lib = Notify.prototype = {

        constructor: Notify,

        init: function(options) {
            // Verifying and validating the input object
            if (!options) {
                options = {};
            }

            // Creating the options object
            this.options = {};

            this.notifyElement = null;

            // Validating the options
            this.options.text = options.text || 'Notify Default'; // Display message
            this.options.status = options.status || 'success'; // Notification status
            if(options.status === 'error' ){
                this.options.status = 'danger';
            }
            this.options.offset = options.offset || 15; // Distance from top edge
            this.options.duration = options.duration || 4000; // Display duration
            if (options.duration === 0 ){
                this.options.duration = 0;
            }
            this.options.close = options.close || false; // Show toast close icon

            // Returning the current object for chaining functions
            return this;

        },

        // Building the DOM element
        buildNotify: function() {

            // Validating if the options are defined
            if (!this.options) {
                throw "Notify is not initialized";
            }

            // Creating the DOM object
            var divElement = document.createElement('div');

            // Default class
            divElement.className = 'alert alert-fixed js-notification show';

            // status level
            divElement.className += ' alert-' + this.options.status;

            // Set Icon
            var iconSvgHref = '';

            switch (this.options.status) {
                case 'info':
                    iconSvgHref = '#icon-circle-info';
                    break;
                case 'warning':
                    iconSvgHref = '#icon-circle-exclamation';
                    break;
                case 'danger':
                case 'error':
                    iconSvgHref = '#icon-circle-cross';
                    break;
                default:
                    iconSvgHref = '#icon-circle-tick';
            }

            // Create Icon HTML
            var iconHtml = '<div class="alert-icon"><div class="icon-ratio"><svg class="svg-icon alert-icon-' + this.options.status + '" aria-label="' + this.options.status + '"><use xlink:href="' + iconSvgHref + '"></use></svg></div></div>';

            // Create close button HTML
            var closeHtml = this.options.close ? '<button aria-hidden="true" class="alert-close js-alert-close" type="button">×</button>' : '';

            // Adding the notify message
            divElement.innerHTML = '<div class="alert-row">' + iconHtml + '<div class="alert-content">' + this.options.text + '</div>' + closeHtml + '</div>';

            // Clear timeout while notify is focused
            if (this.options.duration > 0) {
                var self = this;

                // stop remove countdown
                divElement.addEventListener(
                    'mouseover',
                    function(event) {
                        window.clearTimeout(divElement.timeOutValue);
                    }
                )

                // add back the remove countdown
                divElement.addEventListener(
                    'mouseleave',
                    function(event) {
                        divElement.timeOutValue = window.setTimeout(
                          function() {
                            // Remove the notify from DOM
                            self.removeElement(divElement);
                          },
                          self.options.duration
                        )
                    }
                )
            }

            // Click event on close icon
            if (this.options.close === true) {

                var elCloseButton = divElement.querySelector('.js-alert-close');

                if (elCloseButton){
                    elCloseButton.addEventListener(
                        'click',
                        function(event) {
                            event.stopPropagation();
                            // Remove Notify
                            this.removeElement(divElement);
                            // End any timers
                            window.clearTimeout(divElement.timeOutValue);
                        }.bind(this)
                    );
                    // Binding 'this' for function invocation
                }

            }

            // Returning the generated element
            return divElement;

        },

        // Displaying the notify
        showNotify: function() {

            // Creating the DOM object for the notify
            this.notifyElement = this.buildNotify();

            // Adding the DOM element
            document.body.insertBefore(this.notifyElement, document.body.firstChild);

            // Initial Position
            this.notifyElement.style.right = '-200px';
            this.notifyElement.style.top = this.options.offset + 'px';

            // Force reflow to allow anim
            this.notifyElement.offsetHeight; // force reflow

            // Repositioning the notifys in case multiple notifys are present
            Notify.reposition(this.options.offset);

            // Remove after some time
            if (this.options.duration > 0) {
                this.notifyElement.timeOutValue = window.setTimeout(
                    function() {
                        // Remove the notify from DOM
                        this.removeElement(this.notifyElement);
                    }.bind(this),
                    this.options.duration
                ); // Binding 'this' for function invocation
            }

            // Supporting function chaining
            return this;
        },

        // Removing the element from the DOM
        removeElement: function(notifyElement) {
            // Hiding the element
            notifyElement.classList.remove('show');

            // Removing the element from DOM after transition end
            window.setTimeout(
              function() {
                // Remove the element from the DOM
                notifyElement.parentNode.removeChild(notifyElement);

                // Add a Callback here?

                // Repositioning the toasts again
                Notify.reposition(this.options.offset);

              }.bind(this),
              400
            ); // Binding 'this' for function invocation

        }

    }

    // Positioning the notifys on the DOM
    Notify.reposition = function(offset) {

        // store the offset
        var offsetSize = {
          top: offset
        };

        // Get all notify messages on the DOM
        var allNotifys = document.getElementsByClassName('js-notification');

        // Modifying the position of each notify element
        for (var i = 0; i < allNotifys.length; i++) {

            allNotifys[i].style.right = '15px';

            var height = allNotifys[i].offsetHeight;

            allNotifys[i].style.top = offsetSize.top + 'px';

            offsetSize.top += height + 15;

        }

        // Supporting function chaining
        return this;

    }

    // Setting up the prototype for the init object
    Notify.lib.init.prototype = Notify.lib;


    // Returning the Notify function to be assigned to the window object/module
    return Notify;

});
