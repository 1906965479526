/*!

 File: set-overlay.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:


 CSS Dependencies:
    _site-overlay.scss

 Description:
     Showing and hiding overlay
*/

var setOverlay = {
    timer: null,
    show: function () {
        var me = setOverlay,
            elOverlay = document.querySelector('.js-overlay');

        if(elOverlay && !elOverlay.classList.contains('overlay-animating')){
            clearTimeout(me.timer);
            elOverlay.classList.add('overlay-visible');
            elOverlay.classList.add('overlay-animating');
        }

    },
    hide: function () {
        var me = setOverlay,
            elOverlay = document.querySelector('.js-overlay');

        if(elOverlay && elOverlay.classList.contains('overlay-animating')){
            elOverlay.classList.remove('overlay-animating');
            clearTimeout(me.timer);
            me.timer = setTimeout(function(){
                elOverlay.classList.remove('overlay-visible');
            }, 400);
        }

    }
}
