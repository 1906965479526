/*!

 File: set-job-sign-in.js
 Author: Ember
 Version: 1.0.1 - [CC-344]
 JS Dependencies:
    'helper-on' - helper,

 Description:
    Special function for opening the sign in modal from a job apply modal
    and passing in the current url

*/

var setJobSignIn = {
    init: function (selector) {

        var me = setAjaxButton;

        var clickEvt = function(e){

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest(selector)) return;

            // Button clicked
            e.preventDefault();

            // attach a click event and call 'openMpForm'
            var modalId = el.href;
            // If data attr exists and isn't empty
            if (checkDataAttr(el, 'data-url')) {
                modalId = el.getAttribute('data-url');
            }
            // Magnific is still a jquery plugin
            var elModal = document.querySelector(modalId);

            if(elModal){

                var elForm = elModal.querySelector('.js-ajax-form');
                if(elForm){
                    elForm.setAttribute('data-redirect-url', window.location.href)
                }else{
                    console.log('set-job-sign-in.js cannot find ajax form');
                }
                // Now open the modal
                openMpForm($(elModal), true, false, null, null);
            }else{
                console.log('set-job-sign-in.js cannot find modal');
            }
        }

        // Attach open event
        on('click', document, clickEvt);
    }
}
