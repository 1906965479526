/*!

 File: form-validation-settings.js
 Author: Ember
 Version: 1.0.3
 JS Dependencies:
    jQuery,
    jquery.validate.js    https://jqueryvalidation.org/,

 Description:
    Settings for form validation using Jquery Validate plugin

*/

// ============================================== Easing functions //

jQuery.easing['jswing'] = jQuery.easing['swing'];

jQuery.extend(jQuery.easing,
{
    def: 'easeOutQuad',
    easeOutQuad: function (x, t, b, c, d) {
        return -c * (t /= d) * (t - 2) + b;
    },
    easeOutCubic: function (x, t, b, c, d) {
        return c * ((t = t / d - 1) * t * t + 1) + b;
    },
    easeInOutCubic: function (x, t, b, c, d) {
        if ((t /= d / 2) < 1)
            return c / 2 * t * t * t + b;
        return c / 2 * ((t -= 2) * t * t + 2) + b;
    },
    easeOutQuart: function (x, t, b, c, d) {
        return -c * ((t = t / d - 1) * t * t * t - 1) + b;
    }

});

// ============================================== Form Validation defaults  //

jQuery.validator.setDefaults({
    focusInvalid: false, // so we can smooth scroll
    ignore: ':hidden:not(.select:visible select:hidden), .note-editable', // Ignore hidden fields except selects (within visible divs) which might be hidden by Chosen
    errorPlacement: function (error, element) {
        if (element.parent().hasClass('select')) {
            //error.insertAfter(element.parent()); // If I'm a chosen select then put error after block
            error.appendTo(element.parent());
        } else if (element.parent().hasClass('input-group')) { // If I'm an input group then put error after
            error.insertAfter(element.closest('.form-group').find('.input-group:last'));
        } else if (element.parent().hasClass('date')) {
            error.insertAfter(element.closest('.form-group').find('.date:last')); // If I'm a Date field then put error after
        } else if (element.parent().hasClass('checkbox')) {
            error.insertAfter(element.closest('.form-group').find('.checkbox:last')); // If I'm a custom check then put error after
        } else if (element.parent().hasClass('radio')) {
            error.insertAfter(element.closest('.form-group').find('.radio:last')); // If I'm a custom radio then put error after
        } else if (element.parent().hasClass('single-field')) {
            error.insertAfter(element.closest('.input-group')); // If I'm a single element form
        } else {
            error.insertAfter(element);
        }
    },
    highlight: function (element, errorClass, validClass) {
        if ($(element).parent().hasClass('select')) {
            $(element).closest('.select').addClass(errorClass).removeClass(validClass);
        }
            $(element).closest('.form-group').addClass(errorClass).removeClass(validClass);

    },
    unhighlight: function (element, errorClass, validClass) {
        if ($(element).parent().hasClass('select')) {
            $(element).closest('.select').addClass(validClass).removeClass(errorClass);
        }
            $(element).closest('.form-group').addClass(validClass).removeClass(errorClass);
        
    },
    invalidHandler: function (form, validator) {
        if (!validator.numberOfInvalids()) {
            return;
        }
        // Don't want to scroll if form is inside modal
        //console.log($(form).html());

        if(!$(validator.errorList[0].element).closest('.mfp-modal').length ){
            var $el = $(validator.errorList[0].element);
            var scrollPos = $el.offset().top;
            // Element might be hidden (eg custom select);
            if(!$el.is(":visible")){
                $el.show();
                scrollPos = $el.offset().top;
                $el.hide();
            }
            // Do scroll
            $('html, body').animate({
                scrollTop: scrollPos - 60
            }, 300, 'easeInOutCubic');
        }
    },
    submitHandler: function (form) {
        form.submit();
    }
});


// ============================================== Custom methods  //

// taken from official additional-methods.js

$.validator.addMethod( "time", function( value, element ) {
	return this.optional( element ) || /^([01]\d|2[0-3]|[0-9])(:[0-5]\d){1,2}$/.test( value );
}, "Please enter a valid time, between 00:00 and 23:59" );

$.validator.addMethod( "time12h", function( value, element ) {
	return this.optional( element ) || /^((0?[1-9]|1[012])(:[0-5]\d){1,2}(\ ?[AP]M))$/i.test( value );
}, "Please enter a valid time in 12-hour am/pm format" );


/*
 * Lets you say "at least X inputs that match selector Y must be filled."
 *
 * The end result is that neither of these inputs:
 *
 *	<input class="productinfo" name="partnumber">
 *	<input class="productinfo" name="description">
 *
 *	...will validate unless at least one of them is filled.
 *
 * partnumber:	{require_from_group: [1,".productinfo"]},
 * description: {require_from_group: [1,".productinfo"]}
 *
 * options[0]: number of fields that must be filled in the group
 * options[1]: CSS selector that defines the group of conditionally required fields
 */
 $.validator.addMethod( "require_from_group", function( value, element, options ) {
	var $fields = $( options[ 1 ], element.form ),
		$fieldsFirst = $fields.eq( 0 ),
		validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : $.extend( {}, this ),
		isValid = $fields.filter( function() {
			return validator.elementValue( this );
		} ).length >= options[ 0 ];

	// Store the cloned validator for future validation
	$fieldsFirst.data( "valid_req_grp", validator );

	// If element isn't being validated, run each require_from_group field's validation rules
	if ( !$( element ).data( "being_validated" ) ) {
		$fields.data( "being_validated", true );
		$fields.each( function() {
			validator.element( this );
		} );
		$fields.data( "being_validated", false );
	}
	return isValid;
}, $.validator.format( "Please fill at least {0} of these fields." ) );

// "filone" is the class we will use for the input elements at this example
$.validator.addClassRules("js-validate-at-least-one", {
    require_from_group: [1,".js-validate-at-least-one"]
});

