/*!

 File: set-cookie-warning.js
 Author: Ember
 Version: 1.0.2
 JS Dependencies:
    JS Cookies

 CSS Dependencies:

 Description:
    Cookie Consent Popup

*/
var setCookieWarning = {
    init: function () {
        
        var consentCookie = Cookies.get('cookieconsent_dismissed');

        var clickEvt = function(e){

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest('.js-cookie-dismiss')) return;

            // Button clicked
            e.preventDefault();

            Cookies.set('cookieconsent_dismissed', 'yes', { expires: 365 });
            
            document.body.classList.add('cookie-dismissed-animate');
            document.body.offsetHeight; // force reflow
            document.body.classList.add('cookie-dismissed-animate-down');
        }

        // Attach open event
        on('click', document, clickEvt);

        //
        if(consentCookie=='yes'){
            document.body.classList.add('cookie-dismissed');
        }
        

    },
    destroy: function () {

    }
};

