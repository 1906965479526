/*!

 File: set-truncated-block.js
 Author: Ember
 Version: 1.0.6 open class on button
 JS Dependencies:
    helper-on

 CSS Dependencies:
    _site-truncated-block.scss

 Description:
     Show more block
*/

var setTruncatedBlock = {
    init: function (elTarget, openCallback, closeCallback) {

        var me = setTruncatedBlock,
            elBtns = elTarget.querySelectorAll('.js-truncated-block-btn'),
            elBlock = elTarget.querySelector('.js-truncated-block-inner'),
            timer,
            animSpeed = 400;

        // Need to move slower if tall
        if(elBlock.classList.contains('truncated-block-tall')){
            animSpeed = 800;
        }

        var getHeight = function(){
            // Get height of element (without max-height)
            var clone = elBlock.cloneNode(true);
            clone.style.cssText = 'visibility: hidden; display: block; margin: -999px 0; max-height:none;';
            var height = (elTarget.parentNode.appendChild(clone)).clientHeight;
            elTarget.parentNode.removeChild(clone);
            //height=520;
            //
            return height;
        }

        function btnClick(e) {
            //
            clearTimeout(timer);

            // Closest captures self as well a parents
            var elBtn = e.target.closest('.js-truncated-block-btn');

            if (!elBtn) return;

            if (!elTarget.classList.contains('animating')) {
                console.log('Open Block');
                // Open Block
                elBlock.style.maxHeight = getHeight()+'px';
                //
                elTarget.classList.add('animating');
                // ARIA
                elBtn.setAttribute('aria-expanded', true);
                // set button text
                setText('less');
                // btn class
                elBtn.classList.add('open');
                // after animation
                timer = setTimeout(function(){
                    elTarget.classList.add('active');
                    //
                    elBlock.style.maxHeight = 'none';
                    // run open callback
                    if (typeof openCallback === 'function'){
                        openCallback();
                    }
                },animSpeed);
            }else{
                // Close Block
                // reset height so we can animate
                elBlock.style.maxHeight = getHeight()+'px';
                //
                elTarget.classList.remove('active');
                elTarget.offsetHeight; // force reflow
                elBlock.style.removeProperty('max-height');
                elTarget.classList.remove('animating');
                // ARIA
                elBtn.setAttribute('aria-expanded', false);
                // set button text
                setText('more');
                // btn class
                elBtn.classList.remove('open');
                // run close callback
                timer = setTimeout(function(){
                    if (typeof closeCallback === 'function'){
                        closeCallback();
                    }
                },animSpeed);
            }
        }

        // Go through all buttons and see if they need text changing
        function setText(type){
            forEachNode(elBtns, function (i, elBtn) {
                // Truncated blocks might be nested, btn queryselectorall will get all btns
                if(elTarget === elBtn.closest('.js-truncated-block')){
                    var lessText = elBtn.getAttribute('data-less-text'),
                        moreText = elBtn.getAttribute('data-more-text');
                    //
                    if(type==='more'){
                        if(moreText){
                            // delay to allow open anim
                            //timer = setTimeout(function(){
                                elBtn.textContent = moreText;
                            //},400);
                        }
                    }else{
                        if(lessText){

                            elBtn.textContent = lessText;
                        }
                    }
                }
            });
        }

        /* EVENTS */
        forEachNode(elBtns, function (i, elBtn) {
            // Truncated blocks might be nested, btn queryselectorall will get all btns
            if(elTarget === elBtn.closest('.js-truncated-block')){
                on('click', elBtn, function(e){
                    e.preventDefault();
                    //
                    elBtn.blur();
                    //
                    btnClick(e);
                });
            }
        });


    }
}
