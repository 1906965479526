// ============================================== Misc Functions //

// Any Miscellaneous functions can be added here
//
// Ideally they would eventually be refactored into their own modules
//

var testCallback = function (el, response) {
    console.log('testCallback');
    console.log(response);
};

var testPreCallback = function (el) {
    
    Notify({
        text: 'Pre Callback Success!',
        status: 'success',
        offset: 100
    }).showNotify();

    return(true);

    /*Notify({
        text: 'Pre Callback Error! - ' + el.value + ' is wrong!',
        status: 'danger',
        offset: 100
    }).showNotify();

    return(false);*/

};

var testPreSubmitCallback = function (elForm, callback) {
    console.log('testPreSubmitCallback');
    //
    setTimeout(function(){
        if (typeof callback === 'function') {
            callback();
        }
    }, 2000);
};

/**
 * On profile & registration ensure that onshore roles are cleared if rolesupertype_id = 2
 * In other words if "Are you interested in shorebased positions?" = No
 * These are cleared in the backend but not front.
 */
var profileOnShoreRolesCallback = function(el, response) {
    // Clear selections
    var collection = document.getElementById('onshore-roles').getElementsByTagName('option');
    for(var i = 0; i<collection.length; i++){
        collection[i].selected = false;
    }
    // Refresh chosen module
    $('#onshore-roles').trigger('chosen:updated');
}

/**
 * Check the profile has at least one role on it.
 *
 * @param el
 * @returns {boolean}
 */
var validateRoles = function(el){
    var offShoreRoles = $('#offshore-roles :selected');
    console.log(offShoreRoles.length + ' off-shore roles');
    var onShoreRoles = $('#onshore-roles :selected');
    console.log(onShoreRoles.length + ' on-shore roles');
    return (offShoreRoles.length * 1) + (onShoreRoles.length * 1) > 0;
}
/**
 * Validate and submit the registration form.
 *
 * @param form
 * @param callbackFunction
 */
var submitRegistrationForm = function(form, callbackFunction){
    var isValid = true;
    var requiresOffShoreRoles = $('#offshore-roles').length > 0;
    var hasOnShoreRoles = $('#role-has_onshore_roles').val();
    var offShoreRoles = $('#offshore-roles :selected').length;
    var onShoreRoles = $('#onshore-roles :selected').length;
    console.log(requiresOffShoreRoles ? 'Requires offshore' : 'Does not require offshore');
    //console.log(hasOnShoreRoles ? 'selected' : 'empty');
    console.log(offShoreRoles + ' off-shore roles');
    console.log(onShoreRoles + ' on-shore roles');
    //Make sure user has given a response to the on-shore question, unless this site is onshore only
    if(!hasOnShoreRoles && requiresOffShoreRoles){
        $('#role-has_onshore_roles').closest('.form-group').addClass('error');
        isValid = false;
        console.log('On shore roles unselected');
    }

    if(hasOnShoreRoles > 0 || !requiresOffShoreRoles){
        //Candidate indicated they were interested in on-shore roles - make sure there is at least one on-shore role selected
        if(onShoreRoles < 1){
            $('#onshore-roles').closest('.form-group').addClass('error');
            isValid = false;
            console.log('On shore roles invalid');
        }
        //Set error class on the offshore roles too, if they are empty, as a prompt
        if(offShoreRoles < 1){
            $('#offshore-roles').closest('.form-group').addClass('error');
        }
    }else{
        //User indicated that they were not interested in on-shore roles - make sure there is at least one off-shore role selected
        if(offShoreRoles < 1){
            $('#offshore-roles').closest('.form-group').addClass('error');
            isValid = false;
            console.log('Off-shore roles invalid');
        }
        //Also highlight the off-shore form field if it's empty - but DO NOT mark this as an error
        if(offShoreRoles < 1){
            $('#offshore-roles').closest('.form-group').addClass('error');
        }
    }
    if(isValid){
        console.log('applyRecaptchaCode');
        if (typeof applyRecaptchaCode === 'function') {
            applyRecaptchaCode(form, callbackFunction);
        }
    }else{
        //Re-enable to submit button and the form
        var elSubmitBtn = form.querySelector('.js-submit-btn');
        if (elSubmitBtn) {
            pendingButton.hide(elSubmitBtn);
        }
        var elFormDisable = form.querySelector('.js-form-disable');
        if (elFormDisable) {
            pendingForm.enable(elFormDisable);
        }
    }

}