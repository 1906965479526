/*!

 File: set-float-labels.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:

 CSS Dependencies:

 Description:
    Floating form labels

*/

var setFloatLabels = {
    init: function ($target) {

        var onClass = 'on',
            showClass = 'show';

        // General Inputs
        $target.find('.form-control').unbind('checkval').bind('checkval',function(){
            var $label = $(this).closest('.form-group').find('label.float-label');
            //
            if($(this).val() !== ''){
                $label.addClass(showClass);
            } else {
                $label.removeClass(showClass);
            }
        }).on('input change',function(){
            $(this).trigger('checkval');
        }).on('focus',function(){
            var $label = $(this).closest('.form-group').find('label.float-label');
            if(!$(this).prop('readonly')){
                $label.addClass(onClass);
            }
        }).on('blur',function(){
            var $label = $(this).closest('.form-group').find('label.float-label');
            $label.removeClass(onClass);
        }).delay(200).trigger('checkval');

    },
    destroy: function (target) {
    }
}
