/*!

 File: set-summernote.js
 Author: Ember
 Version: 1.0.3
 JS Dependencies:
    jQuery,
    summernote.js http://summernote.org/
    summernote-floats-bs.js         (https://github.com/MarcosBL/summernote-floats-bs) Use bootstrap classes on image float buttons
    summernote-ext-imgpicker.js     Custom Ember plugin for adding imagepicker images to Summernote
    summernote-img-widths.js        Custom Ember plugin for adding classes to images in Summernote instead of style widths
    data-attr-check.js (check for existance of data attribute)

 CSS Dependencies:
    _custom-summernote.scss

 Description:
    Initialising Summernote WYSIWYG fields

*/


var setSummernote = {
    init: function ($target) {

        var me = setSummernote;

        var buttonList = [
            ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
            ['Layout', ['ul', 'ol']],
            ['insert', ['link']],
            ['Misc', ['undo', 'redo', 'fullscreen', 'codeview']]
        ];

        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-button-template')) {
            template = $target.data('button-template');
            if(template=='basic-formatting'){
                buttonList = [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['Layout', ['ul', 'ol']]
                ];
            }
        }



        // Text colour
        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr($target, 'data-colour-button', true)) {
            buttonList.push(['color', ['color']]);
        }

        // Image picker
        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr($target, 'data-img-button', true)) {
            if(buttonList[2][0]=='insert'){
                // assuming 'insert' is 3rd in array
                buttonList[2][1].push('imagepicker');
            }else{
                // or just Stick it at end
                buttonList.push(['insert', ['imagepicker']]);
            }
        }

        var thisPlaceHolder = $target.attr('placeholder');

        $target.summernote({
            toolbar: buttonList,
            styleTags: ['normal', 'h4'],
            minHeight: 100,
            maxHeight: 250,
            placeholder: thisPlaceHolder,
            disableDragAndDrop: true,
            popover: {
                image: [
                    ['custom', ['imageLink']], // summernote-ember-image-link.js
                    ['imgWidth', ['imgWidthFull', 'imgWidthHalf', 'imgWidthQuarter']], // summernote-ember-img-widths.js
                    ['floatBS', ['floatBSLeft', 'floatBSNone', 'floatBSRight']], // summernote-floats-bs.js Floats
                    ['remove', ['removeMedia']],
                ],
                link: [
                    ['link', ['linkDialogShow', 'unlink']]
                ],
            },
            callbacks: {
                onInit: function() {
                    //$target.show(); // uncomment this to show original text area (for debugging)

                    if($target.prop('disabled') || $target.prop('readonly')){
                        $target.summernote('disable');
                    }
                },
                onFocus: function (e) {
                    var thisEditor = $target.siblings('.note-editor');
                    if (!thisEditor.hasClass('note-active')) {
                        thisEditor.addClass('note-active');
                    }
                },
                onBlur: function (e) {
                    $target.siblings('.note-editor').removeClass('note-active');
                },
                onCreateLink: function (url) {
                    if (url.indexOf('http://') !== 0 &&
                            url.indexOf('https://') !== 0 &&
                            url.indexOf('mailto:') !== 0 &&
                            url.indexOf('tel:') !== 0 &&
                            url.indexOf('#') !== 0 &&
                            url.indexOf('/') !== 0)
                    {
                        url = 'http://' + url;
                    }
                    return url;
                }
            }
        });
        
    },
    empty: function ($target) {
        $target.val('');
        $target.summernote('code', '');
    },
    updateEditor: function ($target) {
        $target.summernote('code', $target.val());
    },
    destroy: function () {
    }
};
