/*!

 File: set-confirm-button.js
 Author: Ember
 Version: 1.0.3 - [MS-976] - no cancel
 JS Dependencies:
    'helper-on' - helper,

 Description:
    Show a confirm pop up before going to new URL

*/

var setConfirmButton = {
    init: function (selector) {

        var clickEvt = function(e){

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest(selector)) return;

            // Button clicked
            e.preventDefault();

            // Get URL
            var updateUrl;

            // If data attr exists and isn't empty
            if (checkDataAttr(el, 'data-href')) {
                updateUrl = el.getAttribute('data-href');
            }
            if (!updateUrl){
                updateUrl = el.getAttribute('href');
            } 

            if(updateUrl){
                // Disable button
                pendingButton.show(el);
                //
                var message = el.getAttribute('data-confirm-message') && el.getAttribute('data-confirm-message') != '' ? el.getAttribute('data-confirm-message') : 'Are you sure?';
                var title;
                if(el.getAttribute('data-confirm-title') && el.getAttribute('data-confirm-title') != ''){
                    title = el.getAttribute('data-confirm-title');
                }
                var btnText = el.getAttribute('data-confirm-btn-text') && el.getAttribute('data-confirm-btn-text') != '' ? el.getAttribute('data-confirm-btn-text') : null;
                
                var noCancel = false;
                // allows data-attribute and data-attribute="true"  otherwise returns false
                if (checkDataAttr(el, 'data-no-cancel', true)) {
                    noCancel = true;
                }
                

                openMpDialog(title, message, function(){
                    // enable button
                    pendingButton.hide(el);
                    // Make request after close
                    setTimeout(function(){
                        window.location.replace(updateUrl);
                    },400);
                    //
                },function(){
                    pendingButton.hide(el);
                }, noCancel, btnText);


            }else{
                console.log('setConfirmButton missing href or data-href');
            }

        }

        // Attach open event
        on('click', document, clickEvt);

    }

}
