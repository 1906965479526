/*!

 File: set-data-tiles.js
 Author: Ember
 Version: 1.0.1 [CC-292] - 
 JS Dependencies:
    'draggable' - library

 Description:
    Functionality for adding / removing data tiles
*/

// this is so it can be passed as a callback through HTML
var addDataTileCB;

var setSortable = {
    init: function (elTarget) {

        var sortable = new Draggable.Sortable(elTarget, {
            draggable: '.js-sortable-item',
            mirror: {
                constrainDimensions: true,
            },
            sortAnimation: {
                duration: 200,
                easingFunction: 'ease-in-out'
            },
            plugins: [Draggable.Plugins.SortAnimation]
        });
        
        // Allow buttons to be clickable
        sortable.on('drag:start', function(event){
            var currentTarget = event.originalEvent.target;
            if(currentTarget.closest('button') || currentTarget.closest('a')){
                event.cancel();
            }
        });

        // declare an axios request's cancelToken
        var ajaxRequest = null; 

        // dragging finished
        sortable.on('drag:stopped', function(){
            
            var saveUrl = elTarget.getAttribute('data-order-update-url');

            // Get url
            var saveUrl = elTarget.getAttribute('data-order-update-url');

            // URL might be further up the tree
            if(!saveUrl || saveUrl==''){
                var elSaveUrl = elTarget.closest('[data-order-update-url]');
                if(elSaveUrl){
                    saveUrl = elSaveUrl.getAttribute('data-order-update-url');
                }
            }

            if(saveUrl){
                var elData = elTarget.querySelectorAll('.js-sortable-item[data-id]');
                if(elData && elData.length>0){
                    
                    // Build array of ids
                    var idArray = [];
                    forEachNode(elData, function (i, el) {
                        idArray.push(el.getAttribute('data-id'));
                    });
                    // put into object
                    var params = {};
                    params['ids'] = idArray;

                    // cancel  previous ajax if exists
                    if (ajaxRequest) {
                        ajaxRequest.cancel('Request cancelled'); 
                    }

                    // create a new token for upcomming ajax (overwrite the previous one)
                    ajaxRequest = axios.CancelToken.source(); 

                    // Send results
                    // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
                    var config = {
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                        },
                        cancelToken: ajaxRequest.token
                    }

                    // Default error message
                    var errorMessage = 'Sorry, there was a problem with your request.';

                    axios.post(saveUrl, params, config)
                        .then(function (response) {
                            var responseJson = response.data;
                            // Returned Success
                            if(responseJson.status=='success'){
                                // Show notification
                                var responseMessage;
                                //
                                if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                                    Notify({
                                        text: responseJson.message.text,
                                        status: 'success',
                                        offset: 100
                                    }).showNotify();
                                }

                                // Check for callback
                                var callback = elTarget.getAttribute('data-callback');

                                // Run callback
                                if (typeof window[callback] === 'function') {
                                    window[callback](me.elTarget, response.data);
                                }
                            }

                            // Returned Error
                            if(responseJson.status=='error'){
                                // See if a message was returned
                                if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                                    errorMessage = responseJson.message.text;
                                }

                                // Show error notification
                                Notify({
                                    text: errorMessage,
                                    status: 'error',
                                    offset: 100
                                }).showNotify();

                            }
                        })
                        .catch(function (error) {

                            // We don't want error messages when request is cancelled
                            if(!axios.isCancel(error)){
                           
                                if(error.response){
                                    errorMessage  += '<br>' + error.response;
                                }
            
                                // Show error notification
                                Notify({
                                    text: errorMessage,
                                    status: 'error',
                                    offset: 100
                                }).showNotify();

                            }
        
                        });


                }

            }
        });

        


    }
}

