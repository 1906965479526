/*!

 File: set-fade-carousel.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'helper-on' - helper,

 Description:
    

*/

var setFadeCarousel = {
    init: function (elTarget) {
        var elSlides = elTarget.querySelectorAll('.js-fade-carousel-item');
        var currentSlide = -1;
        var prevSlide;
        var slideTotal = elSlides.length;

        var speed = 6000;
        var timer;
        var loadTimer;

        var zIndex = 1;

        var advanceSlide = function(){
            currentSlide ++;
            zIndex ++;
            // loop round again
            if(currentSlide >= slideTotal){
                currentSlide = 0;
                zIndex = slideTotal+1;
                resetZindex();
            }
            //
            processSlide(elSlides[currentSlide]);
        }

        var resetZindex = function(){
            forEachNode(elSlides, function (i, el) {
                el.style.zIndex = i;
            });
        }

        var processSlide = function(elSlide){

            console.log('processSlide');
            console.log(elSlide);

            // clear loop
            clearTimeout(loadTimer);

            var isLoaded = elSlide.getAttribute('data-loaded');
            var isLoading = elSlide.getAttribute('data-loading');

            if(!isLoaded) {
                // do this once
                if(!isLoading) {

                    // load image
                    elSlide.setAttribute('data-loading', true);

                    var imgSrc = elSlide.getAttribute('data-src');
                    var imgSrcSet = elSlide.getAttribute('data-src-set');

                    if(imgSrc){
                        var img = new Image();
                        img.addEventListener('load', function onImageLoaded() {
                            img.removeEventListener('load', onImageLoaded);
                            elSlide.style.backgroundImage = 'url(' + imgSrc + ')';
                            elSlide.setAttribute('data-loaded', true);
                        });
                        // start load
                        img.src = imgSrc;
                    }
                }

                // loop round again
                loadTimer = setTimeout(function(){
                    processSlide(elSlide);
                }, 200);
                
            }else{
                // show slide
                showSlide(elSlide);
            }
        }

        var showSlide = function(elSlide){
            elSlide.style.zIndex = zIndex;
            elSlide.classList.add('show');
            elSlide.offsetHeight; // force reflow
            elSlide.classList.add('fade-in');  
            // Reset previous
            if(prevSlide){
                prevSlide.classList.remove('fade-in');
            }
            // Start timer
            clearTimeout(timer);
            timer = setTimeout(function(){
                prevSlide = elSlide;
                if(slideTotal>1){
                    advanceSlide();
                }
                
            }, speed);
        }

        // start process
        advanceSlide();

    }

}
