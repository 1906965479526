/*!

 File: set-file-upload-async.js
 Author: Ember
 Version: 1.0.6 - [CC-431] - handle any dom updates
 JS Dependencies:
    'dropzone' - plugin

 CSS Dependencies:

 Description:
    File upload UI
*/


;(function (root, factory) {
    if ( typeof define === 'function' && define.amd ) {
        define([], function () {
			return factory(root);
		});
	} else if ( typeof exports === 'object' ) {
		module.exports = factory(root);
	} else {
		root.FileUploadAsync = factory(root);
	}
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function (window) {

    'use strict';

    var FileUploadAsync = (function () {

        var Constructor = function () {

            //
    		// Variables
            //
            var publicAPIs = {};
            var settings = {};

            var elUploadDz,
                elUploadDzWrapper,
                elUploadButton,
                elActionsBlock,
                elUpdatesBlock,
                elProgressBar,
                elProgressStatus,
                elThumbsBlock,
                elResponseMarkupBlock,
                elResponseValidate,
                elUploadBtn,
                elError,
                errorMessageDefault;

            var myDropzone,
                updateUrl,
                delUrl,
                thisParamName,
                maxFileTotal;


            //
    		// Methods
            //
            
            /**
    		* Private methods
            */

            var initUploader = function(elTarget){

                elUploadDz = elTarget.querySelector('.js-async-file-upload-dz');
                elUploadDzWrapper = elTarget.querySelector('.js-async-file-upload-dz-wrapper');
                elUploadButton = elTarget.querySelector('.js-async-file-upload-btn');
                elActionsBlock = elTarget.querySelector('.js-async-file-upload-actions');
                elUpdatesBlock = elTarget.querySelector('.js-async-file-upload-update');
                elProgressBar = elTarget.querySelector('.js-progress-bar');
                elProgressStatus = elTarget.querySelector('.js-async-file-upload-status');
                elThumbsBlock = elTarget.querySelector('.js-async-file-upload-thumbs');
                elResponseMarkupBlock = elTarget.querySelector('.js-async-file-upload-response-markup');
                elResponseValidate = elTarget.querySelector('.js-async-file-upload-validate');
                elUploadBtn = elTarget.querySelector('.js-async-file-upload-btn');
                elError = elTarget.querySelector('.js-async-file-upload-error');
                errorMessageDefault = 'Sorry, there was a problem with your request.';
                // Get upload URL
                updateUrl = elTarget.getAttribute('data-update-url');
                // Get delete URL
                delUrl = elTarget.getAttribute('data-delete-url');

                // Get param name
                thisParamName = 'file';
                // If data attr exists and isn't empty
                if (checkDataAttr(elTarget, 'data-name')) {
                    thisParamName = elTarget.getAttribute('data-name');
                }

                maxFileTotal = 1;
                // If data attr exists and isn't empty
                if (checkDataAttr(elTarget, 'data-max-file-total')) {
                    maxFileTotal = elTarget.getAttribute('data-max-file-total');
                }

                // remove item click
                on('click', elTarget, removeBtnClick);

                // Initialise Dropzone
                if(updateUrl && elUploadDz){
                    initDropzone(elTarget)
                }
            }


            var removeTile = function(elTile, tileId){
                // Remove hidden fields
                if(elResponseMarkupBlock){
                    var elResponseItem = elResponseMarkupBlock.querySelector('div[data-id="' + tileId + '"]');
                    if(elResponseItem){
                        elResponseItem.parentNode.removeChild(elResponseItem);
                    }
                }
                // Remove thumb
                elTile.classList.remove('fade-in');
                setTimeout(function() {
                    elTile.parentNode.removeChild(elTile);
                    elThumbsBlock.offsetHeight; // force reflow
                    // check thumb limit
                    checkThumbLimit();
                }, 300);
                
            }


            // Remove file button
            var removeBtnClick = function(e){

                // Don't run if right-click or command/control + click
                if (e.button && e.button !== 0) return;

                // Element within link could have been clicked
                // Closest captures self as well a parents
                if (!e.target.closest('.js-async-file-upload-remove')) return;

                // Button clicked
                e.preventDefault();

                // Get id of clicked tile
                var elTile = e.target.closest('.js-async-file-upload-item');
                var tileId;
                if(elTile){
                    tileId = elTile.getAttribute('data-id');
                }
 
                // confirm removal
                var title = 'Remove file';
                var message = 'Are you sure you want to remove this file?'
                openMpDialog(title, message, function(){

                    // Delete file from the server
                    if(delUrl && tileId){
                        var params = {};
                        params['id'] = tileId;
                        // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
                        var config = {
                            headers: {
                                'X-Requested-With': 'XMLHttpRequest',
                            }
                        }

                        // AJAX request
                        axios.post(delUrl, params, config)
                            .then(function (response) {

                                var responseJson = response.data;

                                // Returned Success
                                if(responseJson.status=='success'){

                                    removeTile(elTile, tileId);

                                    // Bring back to uploader
                                    //revertUploader();

                                    // Process any domUpdate content
                                    if(responseJson.updates && responseJson.updates.length){
                                        responseJson.updates.forEach(function (item) {
                                            var elUpdate = document.querySelector('#' + item.id);
                                            if(elUpdate){
                                                elUpdate.innerHTML = item.html;
                                            }
                                        });
                                    }

                                }

                                // Returned Error
                                if(responseJson.status=='error'){
                                    console.log('delete file error');
                                    console.log(responseJson);
                                }

                            })
                            .catch(function (error) {
                                console.log('delete file error');
                                console.log(error);
                            });


                    }else{
                        removeTile(elTile, tileId);
                        // Just revert back to uploader
                        //revertUploader();
                    }
                    
                }, null, null, null);

            }

            // Reset uploader to upload more files
            var resetUploader = function(){
                myDropzone.removeAllFiles(true);
                // Enable upload button
                elUploadButton.disabled = false;
                // Show Uploader
                elActionsBlock.classList.remove('hidden');
                // hide update
                elUpdatesBlock.classList.add('hidden');
                // Reset update
                elProgressBar.style.width = 0;
            }

            // Initialise Dropzone
            var initDropzone = function(elTarget){

                var acceptedFiles = elTarget.getAttribute('data-accepted-files');
                var maxFilesize = elTarget.getAttribute('data-max-file-size');
                
                // Init dropzone
                myDropzone = new Dropzone(elUploadDz, {
                    url: updateUrl, // Set the url
                    paramName: thisParamName, // The name that will be used to transfer the file
                    //
                    uploadMultiple: true, // multiple upload
                    maxFiles: maxFileTotal, // multiple upload
                    //autoProcessQueue: false, // Make sure the files aren't queued until manually added
                    //
                    maxFilesize: maxFilesize, // MB
                    acceptedFiles: acceptedFiles, // 'image/*,application/pdf,.psd'
                    //
                    previewTemplate: '<div style="display:none"></div>', // We'll make our own previews
                    previewsContainer: null, // We'll make our own previews
                    createImageThumbnails: false, // We'll make our own previews
                    clickable: elUploadBtn, // Define the element that should be used as click trigger to select files.
                    //
                    dictMaxFilesExceeded: 'Too many files' // Change default error message
                });

                // Files have been added
                myDropzone.on('addedfiles', function (files) {  

                    // check that there aren't any rejected files in the list
                    if(myDropzone.files.length === files.length){
                        
                        // Check how many have already been uploaded
                        var elThumbs = elThumbsBlock.querySelectorAll('.js-async-file-upload-item');

                        // check if user trying to upload too many
                        if((files.length + elThumbs.length) <= maxFileTotal) {
                            // reset error
                            elError.innerText = '';
                            elError.classList.add('hidden');
                            // Disable upload button
                            elUploadButton.disabled = true;
                            // Hide Uploader
                            elActionsBlock.classList.add('hidden');
                            // Reset update
                            elProgressBar.style.width = 0;
                            // Show update
                            elUpdatesBlock.classList.remove('hidden');

                            myDropzone.processQueue();
                            
                        }else{
                            // trigger our own error
                            myDropzone.removeAllFiles(true);
                            elError.innerText = 'Error: Too many files';
                            elError.classList.remove('hidden');
                        }

                    }else{
                        // just clear the list
                        myDropzone.removeAllFiles(true);
                    }

                });

                // Once everything is uploaded decide whether we need to hide the uploader or not
                myDropzone.on('queuecomplete', function (file) {
                    // Check thumb limit
                    checkThumbLimit();
                    // Enable save button
                    disableSave(elTarget, true);
                });

                // Sending
                myDropzone.on('sending', function (file, xhr, formData) {
                    disableSave(elTarget);
                    //Add any extra data found in the file element into the formData submitted.
                    var extraData = elTarget.getAttribute('data-extra');
                    if(extraData && extraData != ''){
                        extraData = JSON.parse(extraData);
                        for (var i in extraData) {
                            formData.append(i, extraData[i]);
                            //console.log(i + ' : ' +extraData[i]);
                        }
                    }
                });

                // Update the progress bar
                myDropzone.on('uploadprogress', function (file, progress, bytesSent) {
                    elProgressBar.style.width = (progress + '%');
                    elProgressStatus.innerHTML = progress < 100 ? 'Uploading&hellip;' : 'Processing&hellip;';
                });

                // Response from server
                myDropzone.on('successmultiple', function (file, response) {

                    // If there are still files in the queue then we need to go round again
                    if(this.getQueuedFiles().length){
                        // Disable upload button
                        elUploadButton.disabled = true;
                        // Hide Uploader
                        elActionsBlock.classList.add('hidden');
                        // Reset update
                        elProgressBar.style.width = 0;
                        // Show update
                        elUpdatesBlock.classList.remove('hidden');
                        // Start uploading again
                        myDropzone.processQueue();
                    }
                    
                    // process response
                    var responseJson = $.parseJSON(response);

                    // Returned Success
                    if(responseJson.status=='success'){

                        // Check is array
                        if(!Array.isArray(responseJson.entities)){
                            console.log('set-file-upload-async.js is expecting entities response to be an array');
                            return;
                        }

                        //Loop through entities
                        responseJson.entities.forEach(function (entity) {

                            var elThumbs = elThumbsBlock.querySelectorAll('.js-async-file-upload-item');

                            // Only add thumb if allowed
                            if(elThumbs.length < maxFileTotal){

                                // place any returned markup
                                if(entity.html && entity.html != ''){
                                    // stick the response in the DOM (if it's not already there)
                                    if(elResponseMarkupBlock){
                                        var elResponseItem = elResponseMarkupBlock.querySelector('div[data-id="' + entity.file_id + '"]');
                                        if(!elResponseItem){
                                            // Create a container div for our HTML that we can target later
                                            var elDiv = document.createElement('div');
                                            elDiv.innerHTML = entity.html;
                                            elDiv.setAttribute('data-id', entity.file_id);
                                            elResponseMarkupBlock.appendChild(elDiv);
                                        }
                                        //
                                    }else{
                                        console.log('setFileUploadAsync: missing .js-async-file-upload-response-markup element');
                                    }
                                }

                                // Place thumb
                                if(elThumbsBlock){
                                    // Generate markup
                                    var templateContent = processThumb(entity);
                                    // temp div
                                    var elDiv = document.createElement('div');
                                    elDiv.innerHTML = templateContent;
                                    var elDivInner = elDiv.querySelector('div');
                                    // Just quickly hide the uploader
                                    if(maxFileTotal === '1' || maxFileTotal === 1){
                                        elUploadDzWrapper.classList.add('hidden');
                                    }
                                    // Drop markup
                                    elThumbsBlock.appendChild(elDivInner);
                                    // Fade in
                                    elThumbsBlock.offsetHeight; // force reflow
                                    elDivInner.classList.add('fade-in');
                                }

                            }

                        });

                        // Process any domUpdate content
                        if(responseJson.updates && responseJson.updates.length){
                            responseJson.updates.forEach(function (item) {
                                var elUpdate = document.querySelector('#' + item.id);
                                if(elUpdate){
                                    elUpdate.innerHTML = item.html;
                                }
                            });
                        }

                    }

                    // Returned Error
                    if(response.status=='error'){
                        // See if a message was returned
                        if(response.message && response.message.text && response.message.text != ''){
                            errorMessageDefault = response.message.text;
                        }
                        // reset uploader
                        resetUploader();
                        // show error
                        elError.innerText = 'Error: ' + errorMessageDefault;
                        elError.classList.remove('hidden');
                        // Enable save button
                        disableSave(elTarget, true);
                    }
                    
                });

                myDropzone.on('errormultiple', function (file, errorMessage, xhr) {
                    // reset uploader
                    resetUploader();
                    // show error
                    elError.innerText = 'Error: ' + errorMessage;
                    elError.classList.remove('hidden');
                    // Enable save button
                    disableSave(elTarget, true);
                });

            }

            // Check how many thumbs we have against a total
            var checkThumbLimit = function(){
                var elThumbs = elThumbsBlock.querySelectorAll('.js-async-file-upload-item');
                //
                if(elThumbs.length >= maxFileTotal){
                    // Too many files, hide the uploader
                    elUploadDzWrapper.classList.add('fade-out');
                    setTimeout(function(){
                        elUploadDzWrapper.classList.add('hidden');
                        // reset uploader
                        resetUploader();
                    }, 200);
                }else{
                    // reset uploader
                    resetUploader();
                    // Room for more files, show the uploader
                    elUploadDzWrapper.classList.remove('hidden');
                    elUploadDzWrapper.offsetHeight; // force reflow
                    elUploadDzWrapper.classList.remove('fade-out');
                }

                // We use a hidden field for form validation
                if(elResponseValidate){
                    if(elThumbs.length > 0){
                        elResponseValidate.value = 'Files uploaded';
                    }else{
                        elResponseValidate.value = '';
                    }
                    //
                    elResponseValidate.focus();
                    elResponseValidate.blur();
                }
                
            }

            // Return markup for thumbnail
            var processThumb = function(entity){

                var imgTemplate = '<div class="async-file-upload-item async-file-upload-item-img js-async-file-upload-item" data-id="{file-id}">\
                        <div class="async-file-upload-item-img-container">\
                            <img src="{path}" alt="" />\
                        </div>\
                        <button type="button" class="async-file-upload-item-del js-async-file-upload-remove">Remove this item</button>\
                        <p class="async-file-upload-filename text-smaller">{filename}</p>\
                    </div>';

                var fileTemplate = '<div class="async-file-upload-item js-async-file-upload-item" data-id="{file-id}">\
                        <div class="async-file-upload-item-icon">\
                            <div class="file-icon">\
                                <img src="/assets/front/dist/img/icon-doc-{suffix}.svg" alt="{suffix} Icon">\
                            </div>\
                        </div>\
                        <button type="button" class="async-file-upload-item-del js-async-file-upload-remove">Remove this item</button>\
                        <p class="async-file-upload-filename text-smaller">{filename}</p>\
                    </div>';

                var videoTemplate = '<div class="async-file-upload-item async-file-upload-item-video js-async-file-upload-item" data-id="{file-id}">\
                        <div class="ms-video-player report-video-player vjs-big-play-centered">\
                            <video id="my-video" class="video-js vjs-16-9" controls="" preload="auto" data-setup="{}" width="640" height="360">\
                                <source src="{path}" type="video/mp4">\
                                <p class="vjs-no-js">\
                                    To view this video please enable JavaScript, and consider upgrading to a web browser that\
                                    <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>\
                                </p>\
                            </video>\
                        </div>\
                        <button type="button" class="async-file-upload-item-del js-async-file-upload-remove">Remove this item</button>\
                        <p class="async-file-upload-filename text-smaller">{filename}</p>\
                    </div>';

                var audioTemplate = '<div class="async-file-upload-item js-async-file-upload-item" data-id="{file-id}">\
                        <div class="async-file-upload-item-audio">\
                            <div class="ms-audio-player report-audio-player">\
                                <audio id="my-audio" class="video-js" controls\
                                    preload="auto"\
                                    data-setup=\'{"aspectRatio": "1:0","fluid": true,"controlBar": {"fullscreenToggle": false, "pictureInPictureToggle": false}}\'>\
                                    <source src="{path}" type="audio/mp3"/>\
                                </audio>\
                            </div>\
                        </div>\
                        <button type="button" class="async-file-upload-item-del js-async-file-upload-remove">Remove this item</button>\
                        <p class="async-file-upload-filename text-smaller">{filename}</p>\
                    </div>'

                var fileType = setFileSuffix(entity.extension);
                
                if(!fileType || fileType === undefined){
                    fileType = setFileSuffix(entity.name);
                }
                if(!fileType || fileType === undefined){
                    fileType = setFileSuffix(response.type);
                }

                var template;

                var downloadLink = (entity.path && entity.path != '') ? '<a href="' + entity.path + '" target="_blank" download>' + entity.name + '</a>' : entity.name;

                if(fileType == 'img') {
                    template = imgTemplate;
                    template = template.replaceAll('{path}', entity.path);
                    template = template.replaceAll('{filename}', downloadLink);
                    template = template.replaceAll('{file-id}', entity.id);
                }

                if(fileType == 'pdf' || fileType == 'doc' || fileType == 'xcl' || fileType == 'ppt' || fileType == 'txt') {
                    template = fileTemplate;
                    template = template.replaceAll('{path}', entity.path);
                    template = template.replaceAll('{filename}', downloadLink);
                    template = template.replaceAll('{suffix}', fileType);
                    template = template.replaceAll('{file-id}', entity.id);
                }

                if(fileType == 'mov') {
                    template = videoTemplate;
                    template = template.replaceAll('{path}', entity.path);
                    template = template.replaceAll('{filename}', downloadLink);
                    template = template.replaceAll('{file-id}', entity.id);
                }

                if(fileType == 'audio') {
                    template = audioTemplate;
                    template = template.replaceAll('{path}', entity.path);
                    template = template.replaceAll('{filename}', downloadLink);
                    template = template.replaceAll('{file-id}', entity.id);
                }
                
                return template;

            }
            

            // determine the filetype
            var setFileSuffix = function (filename) {

                if(filename){

                    var fileExtension = filename.substring(filename.lastIndexOf('.') + 1);

                    fileExtension = fileExtension.toLowerCase();

                    // check for back slash escapes
                    fileExtension = fileExtension.replace(/\\\//g, "/");
            
                    var extension;

                    switch (fileExtension) {
                        case 'pdf':
                            extension = 'pdf';
                            break;
                        case 'doc':
                        case 'docx':
                            extension = 'doc';
                            break;
                        case 'xls':
                        case 'xlsx':
                            extension = 'xcl';
                            break;
                        case 'ppt':
                        case 'pptx':
                            extension = 'ppt';
                            break;
                        case 'jpg':
                        case 'jpeg':
                        case 'gif':
                        case 'png':
                        case 'image/jpeg':
                        case 'image/png':
                        case 'image/gif':
                            extension = 'img';
                            break;
                        case 'mov':
                        case 'mp4':
                        case 'webm':
                        case 'ogg':
                        case 'video/mpeg':
                            extension = 'mov';
                            break;
                        case 'mp3':
                        case 'aiff':
                        case 'wav':
                        case 'ogg':
                        case 'audio/mpeg':
                            extension = 'audio';
                            break;
                        default:
                            extension = 'txt';
                    }
                    //
                    return extension;
                }else{
                    return false;
                }
                
            }


            // Disable any local save buttons while uploading
            var disableSave = function (elTarget, enable) {
                var elModal = elTarget.closest('.mfp-modal');
                if(elModal){
                    var elSaveBtn = elModal.querySelector('.js-modal-save');
                    if(elSaveBtn){
                        if(enable){
                            // enable the button
                            elSaveBtn.disabled = false;
                        }else{
                            // disable the button
                            elSaveBtn.disabled = true;
                        }
                        
                    }
                }
            }
            

            /**
            * Public methods
            */

            publicAPIs.init = function (options) {

                // Verifying and validating the input object
                if (!options) {
                    options = {};
                }

                // Validating the options
                settings.el = options.el || null;

                if(settings.el){
                    initUploader(settings.el);
                }else{
                    console.log('set-file-upload-async.js is missing a dom element');
                }

                // Returning the current object for chaining functions
                return this;

            };

            //
    		// Return the Public APIs
    		//

    		return publicAPIs;

        };

        //
    	// Return the Constructor
    	//

    	return Constructor;


    })();

    // Returning the FileUploadAsync function to be assigned to the window object/module
    return FileUploadAsync;

});

