/*!

 File: set-validate-redirect.js
 Author: Ember
 Version: 1.0.1 
 JS Dependencies:
    'closest' - polyfill,

 Description:
    Validate a form and then redirect to the URL on the link - without actually submitting the form

*/

var setValidateRedirect = {
    init: function (selector) {

        var me = setAjaxButton;

        var clickEvt = function(e){

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest(selector)) return;

            // Button clicked
            e.preventDefault();

            var url = el.href ? el.href : el.getAttribute('data-url');

            if(url && url != ''){
                // get nearest form
                var elForm = el.closest('form');
                if(elForm){
                    if($(elForm).valid()){
                        window.location.href = url;
                    }
                }
            }

        }

        // Attach open event
        on('click', document, clickEvt);

    }
}
