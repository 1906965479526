/*!

 File: btn-toggle.js
 Author: Ember
 Version: 1.0.2
 JS Dependencies:
    'closest' polyfill
    'on' helper

 CSS Dependencies:
    _site-dropdown.scss

 Description:
     Set an .active class when btn radios are toggled
*/

var setBtnToggle = {
    init: function () {

        // Catch change event
        var changeEvt = function(e){
            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-btn-toggle')) return;

            // Button clicked
            e.preventDefault();

            var elTarget = e.target.closest('.js-btn-toggle'),
                elClicked = e.target.closest('.btn');

            setBtn(elTarget, elClicked);
        }

        // Set active classes
        var setBtn = function(elTarget, elClicked){

            var currActive = elTarget.querySelector('.active');

            // remove any active
            if(currActive){
                currActive.classList.remove('active');
            }

            // add active
            if (elClicked){
                elClicked.classList.add('active');
            }

        }

        // attach event
        on('change', document, changeEvt);

        // Is a radio already checked?
        var elBtns = document.querySelectorAll('.js-btn-toggle .btn');

        if(elBtns && elBtns.length>0){
            forEachNode(elBtns, function (i, el) {
                var elChecked = el.querySelector('input[type="radio"]:checked');
                if(elChecked){
                    el.classList.add('active');
                }
            });
        }


    }
}
