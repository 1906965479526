/*!

 File: set-entity-search.js
 Author: Ember
 Version: 1.0.2 - return to original state if nothing selected
 JS Dependencies:
    closest - polyfill
     

 CSS Dependencies:

 Description:
    Similar to dependant fields but specific to cases where many checkboxes are updating a single select

*/


var setOptionUpdates = {
    init: function (elTarget) {

        // Build array of selected values to test against eg ['fruit', 'red fruit', 'red/fruit']
        var selectedArray = [];

        var changeEvt = function(e){

            var elChange = e.target;

            // Closest captures self as well a parents
            if (!elChange.closest('[data-option-update-parent]')) return;

            // Button clicked
            e.preventDefault();

            var checked = elChange.checked;

            var value = elChange.getAttribute('data-option-update-group');

            if(value && value != ''){
                var valueArray = value.split(',');
                //
                valueArray.forEach(function (item) {
                    var index = selectedArray.indexOf(item);
                    
                    if(checked){
                        // check item isn't already in array add it 
                        if(index < 0){
                            selectedArray.push(item)
                        }
                    }else{
                        // if item is in array remove it
                        if(index >= 0){
                            selectedArray.splice(index, 1);
                        }
                    }
                });
            }
            // Update the Select
            changeOptions(elTarget, elChange);

        }

        // Find all 'parent' selects and backup their data in data attribute
        var parents = elTarget.querySelectorAll('[data-option-update-self]');
        if(parents && parents.length>0){
            forEachNode(parents, function (i, el) {
                el.setAttribute('data-html', el.innerHTML);
            });
        }

        // Attach event
        on('change', elTarget, changeEvt);

        var changeOptions = function(elTarget, elChange) {

            var elParent = elTarget.querySelector('[data-option-update-self="' + elChange.dataset.optionUpdateParent + '"]');
            var valArray = [];

            if(elParent){

                // Make a list of selected option values so we re-select them after
                var elOptions = elParent.querySelectorAll('option');
                
                forEachNode(elOptions, function (i, el) {
                    if(el.selected && el.value){
                        valArray.push(el.value);
                    }
                });
                
                // restore HTML
                elParent.innerHTML = elParent.getAttribute('data-html');

                // Get options again
                elOptions = elParent.querySelectorAll('option');

                // Put back selected
                forEachNode(elOptions, function (i, el) {
                    // return to default
                    el.selected = false;
                    // if was selected before select again
                    var selectedVal = el.value;
                    var selectedValIndex = valArray.indexOf(selectedVal);
                    if(selectedValIndex >= 0){
                        el.selected = true;
                    }
                });
                
                // If there is anything in the selected array then remove everything but that item
                if(selectedArray.length){
                    // Get options again
                    elOptions = elParent.querySelectorAll('option');
                    //
                    forEachNode(elOptions, function (i, el) {
                        var val = el.getAttribute('data-option-update-group');
                        if(val && val!=""){
                            var valueArray = val.split(',');
                            var removeMe = true;
                            valueArray.forEach(function (item) {
                                var index = selectedArray.indexOf(item);
                                if(index >= 0){
                                    removeMe = false;
                                }
                            });
                            if(removeMe){
                                el.parentNode.removeChild(el);
                            }
                        }
                    });

                    // Remove any empty optgroups
                    elOptGroups = elParent.querySelectorAll('optgroup');
                    forEachNode(elOptGroups, function (i, el) {
                        elOptions = el.querySelectorAll('option');
                        if(elOptions.length < 1){
                            elParent.removeChild(el);
                        }
                    });
                    
                }

                // Update chosen
                if(elParent.classList.contains('js-select')){
                    $(elParent).trigger("chosen:updated");
                }

            }
        }

    },
    destroy: function () {

    }
};
