/*!

 File: set-ajax-update.js
 Author: Ember
 Version: 1.0.18 - [CC-460] - update values, update on error, multiselect
 JS Dependencies:
     'helper-on' - helper,
     'url-search-params-polyfill' - polyfill,
     'closest' - polyfill

 CSS Dependencies:

 Description:
    On blur ajax post contents to an end point

*/

function generateUID() {
    // I generate the UID from two parts here 
    // to ensure the random number provide enough bits.
    var firstPart = (Math.random() * 46656) | 0;
    var secondPart = (Math.random() * 46656) | 0;
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
}

var setAjaxUpdate = {
    init: function (selector) {

        var elContainer;

        // Enable field after response
        var fieldEnable = function(el){
            setTimeout(function(){
                el.disabled = false;
                // Fix issue with field not enabling after value change
                if(el.classList.contains('js-select')){
                    triggerEvent(el,'chosen:updated');
                }
            },200);
        }

        var getContainer = function(el){
            var container = el.closest('.checkbox');
            if(!container){
                container = el.closest('.radio');
            }
            if(!container){
                container = el.closest('.form-group');
            }
            return container;
        }

        var showSpinner = function(el){

            var elContainer = getContainer(el);

            if(elContainer){
                if( typeof elContainer.id === 'undefined' || elContainer.id === ''){
                    elContainer.id = generateUID();
                }
                var id = elContainer.id;
                clearTimeout(window[id + 'a']);
                clearTimeout(window[id + 'b']);
                clearTimeout(window[id + 'c']);
                clearTimeout(window[id + 'd']);
                //
                elContainer.classList.remove('ajax-updating-success');
                elContainer.classList.remove('ajax-updating-failure');
                elContainer.classList.remove('ajax-updating');
                elContainer.classList.remove('ajax-updating-animate');
                //
                elContainer.classList.add('ajax-updating');
                //
                elContainer.offsetHeight; // force reflow
                elContainer.classList.add('ajax-updating-animate');
            }

        }

        var showSpinnerStatus = function(el, status){
            
            var elContainer = getContainer(el);

            if(elContainer){
                var id = elContainer.id;
                window[id + 'a'] = setTimeout(function(){ 
                    window[id + 'b'] = setTimeout(function(){
                        if(status=='success'){
                            elContainer.classList.add('ajax-updating-success');
                        }else{
                            elContainer.classList.add('ajax-updating-failure');
                        }
                        elContainer.offsetHeight; // force reflow
                        elContainer.classList.add('ajax-updating-animate');
                        window[id + 'c'] = setTimeout(function(){
                            elContainer.classList.remove('ajax-updating-animate');
                            window[id + 'd'] = setTimeout(function(){
                                elContainer.classList.remove('ajax-updating');
                                elContainer.classList.remove('ajax-updating-success');
                                elContainer.classList.remove('ajax-updating-failure');
                            }, 200);
                        }, 2000);
                    }, 200);
                }, 400);
            }
        }

        var hideSpinner = function(el){

            var elContainer = getContainer(el);

            if(elContainer){
                var id = elContainer.id;
                elContainer.classList.remove('ajax-updating-animate');
                window[id + 'd'] = setTimeout(function(){
                    elContainer.classList.remove('ajax-updating');
                    elContainer.classList.remove('ajax-updating-success');
                    elContainer.classList.remove('ajax-updating-failure');
                }, 200);
            }

        }

        var postData = function(el, saveUrl, params, config, callback){

            // Default error message
            var errorMessage = 'Sorry, there was a problem with your request.';

            // AJAX request
            axios.post(saveUrl, params, config)
                .then(function (response) {

                    var responseJson = response.data;

                    // Stop Thinking...
                    fieldEnable(el);

                    //
                    if(responseJson.status=='success'){

                        // Show notification
                        var responseMessage;
                        //
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            Notify({
                                text: responseJson.message.text,
                                status: 'success',
                                offset: 100
                            }).showNotify();
                        }

                    }

                    // Returned Error
                    if(!responseJson || responseJson=='' || responseJson.status=='error'){

                        // See if a message was returned
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            errorMessage = responseJson.message.text;
                        }

                        // Show error notification
                        Notify({
                            text: errorMessage,
                            status: 'danger',
                            offset: 100
                        }).showNotify();
                    }


                    

                    // Process any domUpdate content
                    
                    if(responseJson.updates && responseJson.updates.length){
                        responseJson.updates.forEach(function (item, i) {
                            var elUpdate = document.querySelector('#' + item.id);
                            if(elUpdate){
                                if(item.value && item.value !=''){

                                    if(Array.isArray(item.value)){
                                        // updating a multiselect
                                        var madeUpdate = false;
                                        for (var i = 0; i < elUpdate.options.length; i++) {
                                            selected = item.value.indexOf(elUpdate.options[i].value) >= 0;
                                            elUpdate.options[i].selected = selected;
                                            if(selected){
                                                madeUpdate = true;
                                            }
                                        }
                                        if(madeUpdate){
                                            // making a bit of an assumption here to fix CC-467
                                            elUpdate.disabled = false;
                                        }

                                    }else{
                                        elUpdate.value = item.value;
                                    }

                                    if (checkDataAttr(elUpdate, 'data-depends-self', true)) {
                                        triggerEvent(elUpdate,'dependant:change');
                                    }
                                    
                                    if(elUpdate.classList.contains('js-select')){
                                        triggerEvent(elUpdate,'chosen:updated');
                                    }
                                    
                                }
                                if(item.html && item.html !=''){
                                    elUpdate.innerHTML = item.html;
                                    // initialise any custom fields
                                    if(typeof initFields === 'function'){
                                        initFields($(elUpdate));
                                    }
                                }
                                
                            }
                        });
                    }

                    // Show status
                    showSpinnerStatus(el, responseJson.status);
                    
                    // Callback function
                    if (typeof window[callback] === 'function') {
                        window[callback](el, response.data);
                    }

                })
                .catch(function (error) {

                    // Stop Thinking...
                    fieldEnable(el);

                    if(error.response){
                        errorMessage  += '<br>' + error.response;
                    }

                    // Show error notification
                    Notify({
                        text: errorMessage,
                        status: 'danger',
                        offset: 100
                    }).showNotify();

                });
        }

        //
        var changeEvt = function(e){

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest('.js-ajax-update')) return;

            // Button clicked
            e.preventDefault();

            // Get URL
            var saveUrl = el.getAttribute('data-update-url');

            if(saveUrl){

                var thisName = el.getAttribute('name') ? el.getAttribute('name') : el.getAttribute('data-name');
                var thisVal = el.value;

                // Checkbox?
                if(el.type == 'checkbox'){
                    thisVal = el.checked;
                }

                // Multi select?
                if(el.type == 'select-multiple'){
                    // Convert into an array (safe for IE)
                    var optionArray = Array.prototype.slice.call(el.options);
                    thisVal = [];
                    optionArray.forEach(function(option) {
                        if(option.selected){
                            thisVal.push(option.value)
                        }
                    })
                }

                // Validate field
                var elForm = el.closest('form');

                if(elForm){
                    var validator = $(elForm).validate();
                    if(!validator.element(el)){
                        // stop here
                        return;
                    };
                }else{
                    // If we can't validate at least Check if field required
                    if(el.required){
                        if(thisVal === '' || !thisVal.length){
                            
                            // Show error notification
                            Notify({
                                text: 'This field is required, your update has not been saved.',
                                status: 'danger',
                                offset: 100
                            }).showNotify();

                            // stop here
                            return;
                        }
                    }
                }

                // Going to save so disable field
                el.disabled = true;

                // show spinner
                showSpinner(el);

                // Param object
                var params = {};

                // Post using application/x-www-form-urlencoded format (This requires a polyfill!)
                //var params = new URLSearchParams();

                // Option to add value to URL instead of posting params
                //
                // allows data-attribute and data-attribute="true"  otherwise returns false
                if (checkDataAttr(el, 'data-append-url', true)) {
                    saveUrl = saveUrl.replace(/\/$/, "") + '/' + thisVal;
                }else{
                    // Build Params
                    // params.append(thisName, thisVal); // URLSearchParams
                    params[thisName] = thisVal;
                }

                // Add any extra data to Params
                var extraData = el.getAttribute('data-extra');
                if(extraData && extraData != ''){
                    extraData = JSON.parse(extraData);
                    for (var i in extraData) {
                        // params.append(i, extraData[i]);  // URLSearchParams
                        params[i] = extraData[i];
                    }
                }

                // Call back function name can be passed in as a data attribute.
                var callback;
                // If data attr exists and isn't empty
                if (checkDataAttr(el, 'data-callback')) {
                    callback = el.getAttribute('data-callback');
                }

                // Call back function name can be passed in as a data attribute.
                var preCallback;
                // If data attr exists and isn't empty
                if (checkDataAttr(el, 'data-pre-callback')) {
                    preCallback = el.getAttribute('data-pre-callback');
                }


                // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
                var config = {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                }

                // Callback function
                if (typeof window[preCallback] === 'function') {
                    // Run a pre function before posting data
                    if(window[preCallback](el)){
                        // Post the data
                        postData(el, saveUrl, params, config, callback);
                    }else{
                        // Stop Thinking...
                        fieldEnable(el);
                        hideSpinner(el);
                    }
                }else{
                    // Just post the data
                    postData(el, saveUrl, params, config, callback);
                }
                

            }else{
                console.log('setAjaxUpdate missing data-update-url');
            }


        }

        // Attach open event
        on('change', document, changeEvt);

        // Custom selects have their own event
        on('customSelectChange', document, changeEvt);

        // Date pickers have their own event
        on('datePickerChange', document, changeEvt);

        
    },
    destroy: function () {

    }
};
