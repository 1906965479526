/*!

 File: set-dependant-fields.js
 Author: Ember
 Version: 1.0.9 [CCS-876] - account for multi-select fields 
 // THIS HAS DIVERGED FROM ADMIN AND WILL NO LONGER WORK WITH FIELDS THAT HAVE MULTIPLE PARENTS
 
 JS Dependencies:
    jQuery,
    data-attr-check.js (check for existance of data attribute)

 Description:
    Link select fields together with inline content or AJAX requests

*/

var setDependantfields = {
    init: function ($target) {

        var me = setDependantfields;

        var thisAjax = $target.data('ajax'); // Get Select options from an AJAX request
        var thisLinear = $target.data('linear'); // Disable each select until parent is updated

        $target.find('[data-depends-parent]').each(function(){

            var $self = $(this);

            // Save my initial mark up to restore later
            var backup = $self.clone();

            // Find who I'm parented to (could be multiple)
            var parents = $self.data('depends-parent').split(',');


            // build an array of targets
            var $parentArray = [];
            for (var i=0; i < parents.length; i++) {
                //$parentArray.push($target.find('.' + parents[i]));
                $parentArray.push($target.find('[data-depends-self="'+ parents[i] +'"]'));
            }

            // Add change event for each parent in array
            for (var i=0; i < $parentArray.length; i++) {

                $parentArray[i].on('change', function(){
                    $(this).trigger('dependant:change');
                });

                $parentArray[i].on('dependant:change', function(){


                    // Get parent
                    var $thisParent = $(this);

                    // Get parent val (could be checkbox)
                    var thisParentVal = $thisParent.val();
                    if($thisParent.is(':checkbox')){
                        if($($thisParent).prop('checked') != true){
                            thisParentVal = '';
                        }
                    }

                    // Get self selected val
                    var selfSelected = $self.val();

                    // Show loading
                    $self.attr('disabled', true);

                    // Get URL from self otherwise look to parent
                    var url;
                    if($self.data('depends-url')){
                        url = $self.data('depends-url');
                    }else{
                        url = $thisParent.data('depends-url');
                    }

                    // data-disable-option disables self if parent option is selected
                    var isOptionDisabled = false;

                    // If data attr exists and isn't empty
                    if (checkDataAttr($self, 'data-disable-options')) {
                        // Disable self if parent val matches any of the option list
                        var disableOptions = $self.data('disable-options').toString().split(',');

                        //
                        for (var i=0; i < disableOptions.length; i++) {
                            if(disableOptions[i] == thisParentVal){
                                isOptionDisabled = true;
                            }
                        }
                    }

                    // If data attr exists and isn't empty
                    if (checkDataAttr($self, 'data-enable-options')) {
                        isOptionDisabled = true;
                        // Disable self if parent val matches any of the option list
                        var enableOptions = $self.data('enable-options').toString().split(',');
                        //
                        for (var i=0; i < enableOptions.length; i++) {
                            if(enableOptions[i] == thisParentVal){
                                isOptionDisabled = false;
                            }
                        }
                    }

                    // If data attr exists and IS empty
                    if (typeof $self.attr('data-disable-options') !== 'undefined' && $self.attr('data-disable-options') == '') {
                        // Disable self if parent is selected
                        if(thisParentVal!=''){
                            isOptionDisabled = true;
                        }
                    }

                    // data-empty-options will empty self if parent option is selected
                    // allows data-attribute and data-attribute="true"  otherwise returns false
                    var emptyOptions = false;
                    if (checkDataAttr($self, 'data-empty-options', true)) {
                        emptyOptions = true;
                    }

                    if(isOptionDisabled){

                        $self.attr('disabled', true);
                        // clear contents
                        if(emptyOptions){
                            $self.val('');
                        }

                    }else{

                        //$self.attr('disabled', false);

                        if(url){
                            // if URL exists run an AJAX request

                            // If URL is on child it should be possible to have multiple parents

                            // Otherwise

                            // Build URL Params from parent(s)
                            var params = {};

                            // Loop over multiple parents, setting a key => value pair as params
                            if($parentArray.length && $parentArray.length > 1) {
                                for (var j=0; j < $parentArray.length; j++) {
                                    if($parentArray[j].val()){
                                        // paramArray.push($parentArray[j].val()); // option selected by parent eg: 1
                                        params[$parentArray[j].attr('name')] = $parentArray[j].val();
                                    }
                                }
                            } else {
                                // Otherwise, just set an id param, for backwards compatability
                                params['id'] = thisParentVal; // option selected by parent eg: 1

                                // Add any extra params
                                // If data attr exists and isn't empty
                                if (checkDataAttr($thisParent, 'data-depends-params')) {
                                    // Join new params onto existing params
                                    jQuery.extend(params, $thisParent.data('depends-params'));
                                }

                            }

                            params['subentity_property'] = $self.data('depends-property');  // eg: 'sharedAssettype'


                            // AJAX request

                            $.getJSON(url, params, function (responseJson) {

                                if(responseJson){

                                    // Build HTML list
                                    var newOptions = '<option value="">Select an option</option>';

                                    // Select may have custom default text
                                    if($self.find('option:first').val() == ''){
                                        newOptions = '<option value="">' + $self.find('option:first').text() + '</option>';
                                    }

                                    for (var j=0; j < responseJson['data'].length; j++) {
                                        newOptions += '<option value="' + responseJson['data'][j].id + '">' + responseJson['data'][j].value + '</option>';
                                    }

                                    // Add HTML to select
                                    $self.html(newOptions);

                                    // If I have content enable me
                                    if(responseJson['data'].length){
                                        $self.attr('disabled', false);
                                    }

                                    // Post update actions
                                    me.postUpdate($self, selfSelected);

                                }else{
                                    console.log( "setDependantfields ajax request error" );
                                }

                            }).fail(function() {
                                console.log( "setDependantfields ajax request error" );
                            });

                        }else{


                            // if URL doesn't exist then just a regular field (removing options based on class)
                            // Build array of selected values to test against eg ['fruit', 'red fruit', 'red/fruit']
                            var selected = '';
                            var selectedArray = [];

                            for (var j=0; j < $parentArray.length; j++) {
                                if($parentArray[j].val()){

                                    var val = $parentArray[j].val();

                                    if(Array.isArray(val)){
                                        for(i=0; i<=val.length; i++){
                                            if(val[i]){
                                                selectedArray.push(val[i]);
                                            }
                                        }
                                    }else{
                                        selectedArray.push(val);
                                    }

                                    selected += "\\" + $parentArray[j].val();
                                    $self.attr('disabled', false);
                                }else{
                                    // Disable if nothing selected (and in linear mode)
                                    if(thisLinear){
                                        $self.attr('disabled', true);
                                    }else{
                                        $self.attr('disabled', false);
                                    }
                                }
                            };


                            // If parent is a checkbox we're just disabling / enabling and don't need to update any data
                            if($thisParent.is(':checkbox')){
                                var checkEnable = '';
                                // Enable on check
                                // If data attr exists and IS empty
                                if (typeof $self.attr('data-disable-options') !== 'undefined' && $self.attr('data-disable-options') == '') {
                                    if($thisParent.prop('checked') === true){
                                        checkEnable = true;
                                    }else{
                                        checkEnable = false;
                                    }
                                }

                                // Disable on check
                                // If data attr exists and IS empty
                                if (typeof $self.attr('data-enable-options') !== 'undefined' && $self.attr('data-enable-options') == '') {
                                    if($thisParent.prop('checked') === true){
                                        checkEnable = false;
                                    }else{
                                        checkEnable = true;
                                    }
                                }

                                if(checkEnable!=''){
                                    $self.attr('disabled', checkEnable);
                                }

                            }else{

                                // remove first slash
                                selected = selected.substr(1);

                                // Add to array if not already there
                                var tempArray = [];
                                if(selected.includes(',')){
                                    // multi select array
                                    tempArray = selected.split(',');
                                }else{
                                    tempArray.push(selected);
                                }

                                for(var l=0; l<tempArray.length; l++){
                                    // Add to array if not already there
                                    var found = $.inArray(tempArray[l], selectedArray);
                                    //
                                    if(found<0){
                                        selectedArray.push(tempArray[l]);
                                    }
                                }

                                // Restore HTML
                                $self.html(backup.html());

                                // find child options without selected class and remove
                                var removeMe;

                                if(selected){

                                    $self.find('option').each(function(i) {
                                        // we're checking against an array of classes eg 'fruit' 'red fruit' 'red/fruit'
                                        removeMe = true;
                                        for (var k=0; k < selectedArray.length; k++) {

                                            if($(this).hasClass(selectedArray[k])){
                                                removeMe = false;
                                            }

                                            if($(this).val() === ''){
                                                removeMe = false;
                                            }
                                            
                                        }
                                        // Remove elements
                                        if(removeMe){
                                            $(this).remove();
                                        }
                                    });


                                }else{

                                    if(thisLinear){
                                        // Restore HTML
                                        $self.html(backup.html());
                                        // Reset selected
                                        selfSelected = '';
                                        $self.val('');
                                    }

                                }

                            }

                        }

                    }

                    // Post update actions
                    me.postUpdate($self, selfSelected);

                });

                // update children on page load (without triggering validation)
                $parentArray[i].trigger('dependant:change');

            }

        });

    },
    postUpdate:function($self, selfSelected){
        // This function is triggered after processing selection or after ajax request success
        // $self is usually a selct but might be an input or button that we want to disable
        var me = setDependantfields;

        // Restore selected (if it exists)
        if(selfSelected){
            // [EGS-416] selfSelected could be an array (if select is multiple);
            // so turn it into an array as default
            var selfSelectedArray = [];
            //
            if($.isArray(selfSelected)){
                selfSelectedArray = selfSelected;
            }else{
                selfSelectedArray.push(selfSelected);
            }

            for(var i=0; i<selfSelectedArray.length; i++){
                var $thisOption = $self.find('option[value="' + selfSelectedArray[i] + '"]');
                if($thisOption.length){
                    $thisOption.attr('selected','selected');
                }
            }
        }

        // Trigger chosen update
        me.chosenUpdate($self);

        // Trigger a change
        $self.trigger('dependant:change');

    },
    chosenUpdate:function($self){
        // Trigger chosen update (Vue.js instance won't have js-select class)
        if($self.hasClass('js-select') || $self.parent().find('.chosen-container').length){
            $self.trigger('chosen:updated');
        }
    },
    destroy: function () {
    }
};
