/*!

 File: set-window-pop.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:

 CSS Dependencies:

 Description:
    Pop up for social shares

*/

var windowPopup = {
    init: function ($target) {

        var me = windowPopup;

        $target.each(function(){
            var url = $(this).attr('href');
            if (url && url!='#'){
                $(this).click(function(e) {
                    e.preventDefault();
                    me.openWindow(url);
                });
            }
        });
    },
    openWindow: function (url) {

        // Calculate the position of the popup so it’s centered on the screen.
        var width = 500,
            height = 300;

        var left = (screen.width / 2) - (width / 2),
            top = (screen.height / 2) - (height / 2);

        window.open(
            url,
            "",
            "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left
        );

    }
}
