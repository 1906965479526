/*!

 File: set-file-upload-html.js
 Author: Ember
 Version: 1.0.2
 JS Dependencies:
    jQuery,
    set-magnific.js (magnific popup helper functions)

 CSS Dependencies:
    _custom-file-upload-html.scss

 Description:
    Tidy UI for form file uploads: https://www.abeautifulsite.net/whipping-file-inputs-into-shape-with-bootstrap-3

*/

var setHtmlFileupload = {
    init: function ($target) {

        var $fileField = $target.find('input:file');
        var $inputField = $target.find('input:text');

        // Remove thumbnail event
        $target.find('.form-thumbs-container .del').click(function(e){
            e.preventDefault();
            //
            // Modal confirm
            setBootBox.open(null, 'Are you sure you want to delete this file?', function (result) {
                $target.find('.form-thumbs-container').fadeOut(200, function(){
                    $target.find('.file-upload-input').css({'opacity':0, 'display':'table'}).animate({opacity: 1}, 200);
                });
                // Need to click hidden checkbox for saving
                $target.find('.file-upload-remove input:checkbox').prop('checked', true);
            });
        });

        // Remove file path event
        $target.find('.file-upload-input .del').click(function(e){
            e.preventDefault();
            // Reset contents of text
            $inputField.val('');
            // Reset contents of file
            $fileField.wrap('<form>').closest('form').get(0).reset();
            $fileField.unwrap();
            // Hide button
            $(this).hide();
        });


        $fileField.change(function(){
            var numFiles = $(this).get(0).files ? $(this).get(0).files.length : 1;
            var label = $(this).val().replace(/\\/g, '/').replace(/.*\//, '');
            //var $input = $(this).parents('.input-group').find('input:text');
            var log = numFiles > 1 ? numFiles + ' files selected' : label;
            if($inputField.length ) {
                $inputField.val(log);
                $target.find('.file-upload-input .del').show();
            } else {
                if( log ) alert(log);
            }
        });

    }
}
