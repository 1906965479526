/*!

 File: set-mobile-nav.js
 Author: Ember
 Version: 1.0.4 - disable when resized
 JS Dependencies:
    set-overlay,
    helper-on,
    'closest' polyfill
    body scroll lock


 CSS Dependencies:

 Description:
     Open close mobile menu
*/

var setMobileNav = {
    buttonClick: null,
    init: function () {

        var mediaQuery;
        

        function handleTabletChange(e) {
            // Check if the media query is true
            if (e.matches) {
                // Then log the following message to the console
                me.closeNav();
            }
        }


        var me = setMobileNav;

        // Button click
        me.buttonClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-mobile-nav-btn')) return;

            // Button clicked
            e.preventDefault();

            // Open or close
            if(document.body.classList.contains('mob-nav-open')){
                me.closeNav();
                // remove close eventHandler
                off('click', document, navOutClick);
                // Remove MQ event listener
                mediaQuery.removeListener(handleTabletChange);
            }else{
                me.openNav();
                // set close eventHandler
                on('click', document, navOutClick);
                // Set close when window resized
                mediaQuery = window.matchMedia('(min-width: 720px)');
                // Register MQ event listener
                mediaQuery.addListener(handleTabletChange);
            }
        };

        // Click outside
        var navOutClick = function(e){
            if(!e.target.closest('.js-mobile-nav-click')){
                // remove close eventHandler
                off('click', document, navOutClick);
                //
                e.preventDefault();
                me.closeNav();
            }
        }

        // attach event
        on('click', document, me.buttonClick);
    },
    openNav: function(){
        // Show overlay
        setOverlay.show();
        // add class
        document.body.classList.add('mob-nav-open');
        // Body scroll lock
        var targetElement = document.querySelector('.js-main-nav');
        if(targetElement){
            bodyScrollLock.disableBodyScroll(targetElement, {
                reserveScrollBarGap: true,
            });
        }
        
    },
    closeNav: function(){
        // hide overlay
        setOverlay.hide();
        // remove class
        document.body.classList.remove('mob-nav-open');
        // Body scroll lock
        bodyScrollLock.clearAllBodyScrollLocks();
    },
    destroy: function(){

        var me = setMobileNav;

        // close nav
        me.closeNav();

        // detatch event
        off('click', document, me.buttonClick);

    }
}
