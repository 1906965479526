/*!

 File: set-magnific.js
 Author: Ember
 Version: 1.0.4 - [CCS-653] - native js confirm if from modal
 JS Dependencies:
    jQuery,
    jquery.magnific-popup.js,

 Description:
    Helper functions for displaying Magnific PopUps

*/

// Set an open modal button

var setMpForm = {
    init: function ($target, callbackOpen, callbackClose) {

        // If data attr exists and isn't empty
        var isModal = false;
        if (checkDataAttr($target, 'data-modal')) {
            isModal = true;
        }

        // attach a click event and call 'openMpForm'
        var modalId = $target.attr('href');
        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-url')) {
            modalId = $target.data('url');
        }

        var $thisModal = $(modalId).first();

        $target.click(function(e){
            e.preventDefault();
            openMpForm($thisModal, isModal, false, callbackOpen, callbackClose);
        });

    },
    destroy: function () {

    }
};

// Open a magnific Form modal (from a js function)
var openMpForm = function($target, isModal, focusInput, callbackOpen, callbackClose){

    var scrollPos = 0;

    var delay = 0;

    var mfp = $.magnificPopup.instance;

    // check if modal already exists
    if(mfp.currItem){
        $.magnificPopup.close();
        // need a delay to allow first to close before second can open :(
        delay = 1000;
    }

    setTimeout(function(){
        $.magnificPopup.open({
            items: {
                src: $target,
                type: 'inline'
            },

            alignTop: true, // Stick to top of page
            preloader: false,
            removalDelay: 500, //delay removal by X to allow out-animation
            overflowY: 'scroll',
            mainClass: 'mfp-zoom-in',
            modal: isModal,

            // When element is focused, some mobile browsers in some cases zoom in
            // It looks not nice, so we disable it:
            callbacks: {
                beforeOpen: function() {
                    if(focusInput){
                        if($(window).width() < 700) {
                            this.st.focus = false;
                        } else {
                            this.st.focus = 'input';
                        }
                    }
                    // Get current scrollpos
                    scrollPos = $('html').scrollTop();
                },
                open: function(){
                    // Close button
                    $target.find('.js-mfp-close').unbind('click').click(function(e){
                        e.preventDefault();
                        $.magnificPopup.close();
                    });
                    // Run Callback function
                    if (typeof callbackOpen === 'function'){
                        callbackOpen();
                    }
                    //
                    // reset scroll
                    $('body').scrollTop( scrollPos );

                },
                close:function(){
                    // Enable any disabled Buttons
                    $target.find('.btn-pending').each(function(){
                        pendingButton.hide($(this));
                    });
                    // Run Callback function
                    if (typeof callbackClose === 'function'){
                        callbackClose();
                    }
                }
            }
        });
    }, delay);

}


// Open a magnific dialog (Bootbox replacement)
var openMpDialog = function(headline, message, cbConfirm, cbCancel, noCancel) {
    var classes = "mfp-modal mfp-with-anim dialog-modal";
    if(!headline){
        classes += " dialog-modal-no-headline";
    }
    var dialogHtml = '<div class="' + classes + '">';
        if (headline) {
            dialogHtml += '<div class="modal-header"><h4 class="modal-title">' + headline + '</h4></div>';
        }
        if (message) {
            dialogHtml += '<div class="modal-body"><p>' + message + '</p></div>';
        }
        dialogHtml += '<div class="modal-footer">';
            if(!noCancel){
                dialogHtml += '<button type="button" class="btn btn-white js-btn-cancel">Cancel</button> ';
            }
            dialogHtml += '<button type="button" class="btn btn-primary js-btn-submit">OK</button>';
        dialogHtml += '</div>';
    dialogHtml += '</div>';

    // Magnific doesn't support multiple popups, so cancel if one is already open
    // Answer would be to reinstate bootbox bootstrap modals just for these alerts
    if(!$.magnificPopup.instance.isOpen){

        // Open Popup
        $.magnificPopup.open({
            items: {
                src: dialogHtml,
                type: 'inline'
            },

            //alignTop: true, // Stick to top of page
            preloader: false,
            removalDelay: 500, //delay removal by X to allow out-animation
            overflowY: 'scroll',
            mainClass: 'mfp-zoom-in',
            modal: true,

            callbacks: {
                open: function() {
                    var $content = $(this.content);

                    $content.on('click', '.js-btn-submit', function() {
                        // Close popup
                        $.magnificPopup.close();
                        // Remove Key down
                        $(document).off('keydown', keydownHandler);
                        // Run Callback
                        if (typeof cbConfirm == 'function') {
                            setTimeout(function(){
                              cbConfirm();
                            }, 300);
                        }
                    });

                    $content.on('click', '.js-btn-cancel', function() {
                        // Close popup
                        $.magnificPopup.close();
                        // Remove Key down
                        $(document).off('keydown', keydownHandler);
                        // Run Callback
                        if (typeof cbCancel == 'function') {
                            setTimeout(function(){
                              cbCancel();
                            }, 300);
                        }
                    });

                    // Enter / Escape keycodes
                    var keydownHandler = function (e) {
                        if (e.keyCode == 13) {
                            $content.find('.js-btn-submit').click();
                            return false;
                        } else if (e.keyCode == 27) {
                            $content.find('.js-btn-cancel').click();
                            return false;
                        }
                    };

                    $(document).on('keydown', keydownHandler);
                }
            }
        });

    }else{
        
        console.log("openMpDialog can't be called from a modal");

        if (window.confirm( headline + ' - ' + message )) {
            // run callback
           if (typeof cbConfirm == 'function') {
               cbConfirm();
           }
        }
       
    }

};
