// ============================================== Initialise Fields //

// This will initialise all JS enhanced form fields
// Run this function on any newly created objects if needed

var initFields = function ($parent, callback) {

    //console.log('initFields');

    var $container = $('body');

    if ($parent) {
        $container = $parent;
    }

    // Chosen select
    $container.find('.js-select').each(function () {
        setSelect.init($(this));
    });

    // HTML file upload
    $container.find('.js-file-upload').each(function () {
        setHtmlFileupload.init($(this));
    });

    // bootstrap-datepicker
    $container.find('.js-datepicker').each(function () {
        setDatePicker.init($(this));
    });

    // Init Summernote only on visible items (for performance)
    $container.find('.js-wysiwyg:visible').each(function () {
        setSummernote.init($(this));
    });

    // Image carousel
    /*$container.find('.js-carousel').each(function(){
        setImgCarousel.init($(this));
    });*/

    // AJAX form submit
    $container.find('.js-ajax-form').submit(function (e) {
        e.preventDefault();
        // Validated forms should have their own submit handler
        if (!$(this).hasClass('js-validate-form')) {
            submitAjaxForm($(this));
        }
    });

    // General form validation
    $container.find('.js-validate-form').each(function () {
        $(this).validate({
            submitHandler: function (form) {

                var cbFunction = function () {
                    if ($(form).hasClass('js-ajax-form')) {
                        submitAjaxForm($(form));
                    } else {
                        form.submit();
                    }
                }

                // If data attr exists and isn't empty
                if (checkDataAttr(form, 'data-pre-submit-callback')) {
                    var callback = form.getAttribute('data-pre-submit-callback');
                    if (typeof window[callback] === 'function') {
                        window[callback](form, cbFunction);
                    }
                } else {
                    cbFunction();
                }


            }
        });
    });

    // open a modal form
    $container.find('.js-mpop-form').each(function () {
        setMpForm.init($(this));
    });

    // Set modal close event
    $container.find('.js-mpop-close').each(function () {
        $(this).off().on('click', $(this), function (e) {
            $.magnificPopup.close();
        });
    });

    // Floating form labels
    $container.find('.float-labels').each(function () {
        setFloatLabels.init($(this));
    });

    // Dependant fields
    $container.find('.js-dependant-fields-group').each(function () {
        setDependantfields.init($(this));
    });

    // Dependant blocks
    $container.find('.js-dependant-block-group').each(function () {
        setDependantblocks.init($(this));
    });

    // File upload dropzone
    var elAsyncFileUploads = $container[0].querySelectorAll('.js-async-file-upload'); // NodeList
    if (elAsyncFileUploads && elAsyncFileUploads.length > 0) {
        forEachNode(elAsyncFileUploads, function (i, el) {
            var elAsyncFileUpload = new FileUploadAsync;
            // Initialise uploader
            elAsyncFileUpload.init({
                el: el
            });
        });
    }

    // validate form and then disable
    $container.find('.js-validate-and-disable').each(function () {
        $(this).validate({
            submitHandler: function (form) {
                var delay = 0;
                // Disable form / button
                var elSubmitBtn = form.querySelector('.js-submit-btn');
                if (elSubmitBtn) {
                    pendingButton.show(elSubmitBtn);
                }
                var elFormDisable = form.querySelector('.js-form-disable');
                if (elFormDisable) {
                    pendingForm.disable(elFormDisable);
                }

                var cbFunction = function () {
                    form.submit();
                }
                // If data attr exists and isn't empty
                if (checkDataAttr(form, 'data-pre-submit-callback')) {
                    var callback = form.getAttribute('data-pre-submit-callback');
                    if (typeof window[callback] === 'function') {
                        window[callback](form, cbFunction);
                    }
                } else {
                    cbFunction();
                }
                // Hide any passwords while submitting
                /*var elShowPwds = form.querySelectorAll('.js-show-pwd');
                if(elShowPwds && elShowPwds.length>0){
                    forEachNode(elShowPwds, function (i, el) {
                        setPwdShow.hidePwd(el);
                    });
                }*/
            }
        });
    });

    // Init Google Recaptcha
    var elRecaptcha = $container[0].querySelectorAll('.g-recaptcha');
    if (elRecaptcha && elRecaptcha.length > 0) {
        forEachNode(elRecaptcha, function (i, el) {
            if (!el.querySelector('iframe')) {
                var siteKey = el.getAttribute('data-sitekey');
                grecaptcha.render(el, {
                    sitekey: siteKey,
                    callback: function (response) {
                        //console.log(response);
                    }
                });
            } else {
                // reset existing
                grecaptcha.reset(el);
            }

        });
    }

    // Init Multi Select Columns
    var elMSColumns = $container[0].querySelectorAll('.js-multi-select-columns'); // NodeList
    if (elMSColumns && elMSColumns.length > 0) {
        forEachNode(elMSColumns, function (i, el) {
            var mSCol = new MSColumn;
            mSCol.init({
                el: el
            });
        });
    }


    // Run Callback function
    if (typeof callback === 'function') {
        callback();
    }

};

var deleteAccountCallback = function (el, response) {
    if (response.status == 'success') {
        //Show a pop-up with a message, then redirect
        openMpDialog('Account Deleted', 'Your account has now been deleted.<br /><br />We\'re sorry to see you go, and would like to thank you for your interest in Crew and Concierge. Please do not hesitate to contact us if you wish to rejoin in the future!', function () {
            window.location.href = '/login';
        }, null, true);

    }
}

var foodPicturesCallback = function (el, response) {
    var elFoodModalBtn = document.querySelector('.js-food-pictures-preview-btn');
    if (elFoodModalBtn) {
        elFoodModalBtn.classList.remove('hidden');
    }
    console.log('foodPicturesCallback');
    /*
    var elFoodModalContent = document.querySelector('.js-food-pictures-modal-content');
    if(elFoodModalContent){
        elFoodModalContent.innerHTML = '<p>Returned food content will go here</p>';
    }
    
    if(response.status == 'success'){
        console.log(response);
    }
    */
}

var closeModalsCallback = function (el, response) {
    if (response && response.status == 'success') {
        setTimeout(function () {
            $.magnificPopup.close();
        }, 400);
    }
}

// ============================================== //

// DOM READY  //

// ============================================== //


// Vanilla JS DOM Ready
document.addEventListener('DOMContentLoaded', function () {

    setMobileNav.init();

    var elCookie = document.querySelector('.js-cookie-dismiss');
    if (elCookie) {
        setCookieWarning.init();
    }

    // Dropdowns
    var elDropdown = document.querySelector('.js-dropdown-toggle');
    if (elDropdown) {
        setDropdown.init();
    }

    // Toggle Buttons
    var elToggleBtns = document.querySelector('.js-btn-toggle');
    if (elToggleBtns) {
        setBtnToggle.init();
    }

    // Show more / truncated block
    var elTruncatedBlocks = document.querySelectorAll('.js-truncated-block'); // NodeList
    if (elTruncatedBlocks && elTruncatedBlocks.length > 0) {
        forEachNode(elTruncatedBlocks, function (i, el) {
            setTruncatedBlock.init(el);
        });
    }

    // AJAX form submit
    var elAjaxForms = document.querySelectorAll('.js-ajax-form'); // NodeList
    if (elAjaxForms && elAjaxForms.length > 0) {
        forEachNode(elAjaxForms, function (i, el) {
            // Validated forms should have their own submit handler
            if (!el.classList.contains('js-validate-form')) {
                on('submit', el, function (e) {
                    e.preventDefault();
                    submitAjaxForm(el);
                });
            }
        });
    }

    // AJAX button update
    // Not testing for existance as fields might be in datatable
    setAjaxButton.init('.js-ajax-btn');

    // AJAX field update
    // Not testing for existance as fields might be in datatable
    setAjaxUpdate.init('.js-ajax-update');


    // AJAX Modal Form
    // Not testing for existence as fields might be in datatable
    setAjaxModalForm.init('.js-ajax-modal-form-btn');

    // AJAX button update
    // Not testing for existance as fields might be in datatable
    setValidateRedirect.init('.js-validate-redirect-btn');

    // Confirm button
    // Not testing for existence as fields might be in datatable
    setConfirmButton.init('.js-confirm-btn');

    // Set close event for all alerts
    var elClose = document.querySelector('.js-alert-close');
    if (elClose) {
        // Initialise pop up button
        var alertCloseBtnClick = function (e) {

            // Don't run if right-click or command/control + click
            if (e.button && e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-alert-close')) return;

            // Button clicked
            e.preventDefault();

            // Hide current alert
            formAlert.hide(e.target.closest('.js-alert'));

        }

        // Attach open event
        on('click', document, alertCloseBtnClick);
    }

    // INIT DATA TILES
    var elDataTiles = document.querySelector('.js-data-tiles-list');
    if (elDataTiles) {
        setDataTiles.init('.js-data-tiles-list');
    }

    // INIT JOB SEARCH
    var elEntitySearch = document.querySelector('.js-entity-search');
    if (elEntitySearch) {
        setEntitySearch.init(elEntitySearch);
    }

    // INIT OPTION UPDATE
    var elOptionUpdates = document.querySelectorAll('.js-option-update'); // NodeList
    if (elOptionUpdates && elOptionUpdates.length > 0) {
        forEachNode(elOptionUpdates, function (i, el) {
            setOptionUpdates.init(el);
        });
    }

    // Special function for opening the sign in modal from a job apply modal
    // and passing in the current url
    setJobSignIn.init('.js-job-sign-in');

    // Home fade carousel
    var elFadeCarousel = document.querySelector('.js-fade-carousel');
    if (elFadeCarousel) {
        setFadeCarousel.init(elFadeCarousel);
    }

    // Initialise sortable data tiles
    var elSortable = document.querySelectorAll('.js-sortable');
    if (elSortable && elSortable.length > 0) {
        forEachNode(elSortable, function (i, el) {
            setSortable.init(el);
        });
    }

    var elGlideCarousel = document.querySelector('.js-testimonial-carousel');

    if (elGlideCarousel) {
        var elGlideCarouselItems = elGlideCarousel.querySelectorAll('.glide__slide');
        if(elGlideCarouselItems.length>1){
            var thisSpeed = 4000;
            // If data attr exists and isn't empty
            if (checkDataAttr(elGlideCarousel, 'data-speed')) {
                thisSpeed = parseInt(elGlideCarousel.getAttribute('data-speed')) * 1000;
            }
            new Glide('.js-testimonial-carousel', {
                type: 'carousel',
                autoplay: thisSpeed,
                animationDuration: 600
            }).mount()
        }else{
            var elBullets = elGlideCarousel.querySelector('.glide__bullets');
            if(elBullets){
                elBullets.style.display = 'none';
            }
            
        }
    }

   
    // var headerWatcher = scrollMonitor.create(150);

    // headerWatcher.fullyEnterViewport(function () {
    //     document.body.classList.remove('header-scrolled');
    //     //elScroller.classList.add('animating');
    // });

    // headerWatcher.partiallyExitViewport(function () {
    //     document.body.classList.add('header-scrolled');
    // });

});


// $jquery dom ready
$(document).ready(function () {

    // Cookie Warning Overlay
    //setCookieWarning.init();

    // Social share popups
    //windowPopup.init($('.js-social-share'));

    // initialise all JS enhanced form fields
    initFields();

    // Is there a popup that needs opening on page load?
    var $loadModal = $('#registration-modal').first();
    //console.log($loadModal);
    var $loadModal = $loadModal.length ? $loadModal : $('#dashboard-modal').first();
    //console.log($loadModal);
    var $loadModal = $loadModal.length ? $loadModal : $('#detected-location-modal').first();
    if ($loadModal.length) {
        var isModal = false;
        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr($loadModal, 'data-is-modal', true)) {
            isModal = true;
        }
        var callbackOpen = false;
        var callbackClose = false;
        openMpForm($loadModal, isModal, false, callbackOpen, callbackClose);
    }

    //********** Registration form validation **************//

    $('#registration-form .form-group').on('click', function(){
           $(this).removeClass('error');
    });

    // $('#registration-form').on('submit', function(e){
    //     let isValid = validateRegistrationForm();
    //     alert('Form is valid:' + isValid);
    //     e.preventDefault();
    //     return false;
    // });

    //Do the roles in the registration flow need validating?
    $('.js-registration-step-submit [data-step="professional"]').on('click', () => {
        let isValid = validateRoles($(this));
        if (!isValid) {
            //alert('You must add at least one role to your profile to continue');
            return false;
        }
    });

});