/*!

 File: form-alerts.js
 Author: Ember
 Version: 1.0.3 - [MINA-706]
 JS Dependencies:
    helper-on
    DOMPurify

 Description:
    Helper function for showing / hiding alerts

*/

var formAlert = {
    showTimerA:{},
    showTimerB:{},
    hideTimerA:{},
    hideTimerB:{},
    hideAfterTimer:{},
    //
    show: function (elTarget, alertText, hideAfter, status) {

        var me = formAlert;

        // might have passed in a jquery object
        if (elTarget instanceof jQuery){
            elTarget = elTarget[0];
        }

        // Elements
        var elAlertInner = elTarget.querySelector('.alert');
        var elAlertText = elTarget.querySelector('.js-alert-content');


        // Clear Timers
        me.clearTimers();


        // Set Text
        if(alertText && alertText!=''){
            if(elAlertText){
                if(typeof DOMPurify == 'function'){
                   alertText = DOMPurify.sanitize(alertText);
                   elAlertText.innerHTML = alertText;
                   // Run init fields as there may be a modal link in there 
                   initFields($(elAlertText));
                }else{
                    elAlertText.innerText = alertText;
                }
                
            }
        }

        // Set classes
        elAlertInner.classList.remove('alert-success', 'alert-danger', 'alert-warning', 'alert-info');
        if(status === 'error' || status === 'danger'){
            elAlertInner.classList.add('alert-danger');
        }else{
            elAlertInner.classList.add('alert-success');
        }


        // Get height
        var alertHeight = elAlertInner.offsetHeight;
        alertHeight += parseInt(window.getComputedStyle(elAlertInner).getPropertyValue('margin-bottom'));
        // Set height
        elTarget.style.maxHeight = alertHeight + 'px';

        // Show alert
        me.showTimerA = setTimeout(function(){
            // Fade in
            elTarget.classList.remove('alert-hidden');
            // Remove inline styles after anim
            me.showTimerB = setTimeout(function(){
                elTarget.style.maxHeight = '';
                // Hide again
                if(hideAfter){
                    me.hideAfterTimer = setTimeout(function(){
                        me.hide(elTarget);
                    }, 5000);
                }
            }, 500);
        }, 300);
    },
    hide: function (elTarget) {

        var me = formAlert;

        // might have passed in a jquery object
        if (elTarget instanceof jQuery){
            elTarget = elTarget[0];
        }

        // Elements
        var elAlertInner = elTarget.querySelector('.alert');

        // Clear Timers
        me.clearTimers();

        // Get height
        var alertHeight = elAlertInner.offsetHeight;
        alertHeight += parseInt(window.getComputedStyle(elAlertInner).getPropertyValue('margin-bottom'));

        // Set Height
        elTarget.style.maxHeight = alertHeight + 'px';

        // Fade out
        elTarget.classList.add('alert-hidden');

        // Close gap
        me.hideTimerA = setTimeout(function(){
            elTarget.style.maxHeight = '0px';
            me.hideTimerB = setTimeout(function(){
                elTarget.style.maxHeight = '';
            }, 500);
        }, 300);

    },
    reset: function (elTarget) {
        elTarget.classList.add('alert-hidden');
        elTarget.style.maxHeight = '';
    },
    clearTimers: function(){
        var me = formAlert;
        //
        clearTimeout(me.showTimerA);
        clearTimeout(me.showTimerB);
        clearTimeout(me.hideTimerA);
        clearTimeout(me.hideTimerB);
        clearTimeout(me.hideAfterTimer);
    }
};
