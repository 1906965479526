/*!

 File: set-dependant-blocks.js
 Author: Ember
 Version: 1.0.13 - [MS-682] init summernote
 JS Dependencies:
    jQuery,
    data-attr-check.js (check for existance of data attribute)

 Description:
    Use a select field to show blocks of content with an AJAX request

*/

// ============================================== Dependant fields set up //

var setDependantblocks = {
    init: function ($target) {

        var me = setDependantblocks;

        var $selector = $target.find('.js-dependant-block-field')
            .not($target.find('.js-dependant-block-group .js-dependant-block-field')); // don't want to target js-dependant-block-group within js-dependant-block-group
            //.not($target.find('.grid-row .js-dependant-block-field')); // don't want to target dependant block grids within js-dependant-block-group

        $selector.each(function(){

            var $controlField = $(this);
            var $resultsContainer = $target.find('.js-dependant-block-target');

            // does select have a url
            var url = $controlField.data('depends-block-url');

            $controlField.on('change', function(){
                $(this).trigger('dependant:change');
            });

            $controlField.on('dependant:change', function(){

                if(url){

                    // AJAX request
                    if($(this).val() != '' ){
                        // Grab the id of the seletion
                        var params = {};
                        params['id'] = $(this).val(); // option selected by parent eg: 1

                        // loop through every element that has js-dependant-block-extra-field and add it's value to params
                        $target.find('.js-dependant-block-extra-field').each(function(){
                            if($(this).val() && $(this).val()!=''){
                                params[$(this).attr('name')] = $(this).val();
                            }
                        });

                        // Are there any extra paremters to send?
                        // If data attr exists and isn't empty
                        if (checkDataAttr($controlField, 'data-depends-block-params') || $controlField.data('depends-block-params') != undefined) {
                            // Join new params onto existing params
                            jQuery.extend(params, $controlField.data('depends-block-params'));
                        }

                        // Fire off a request to the endpoint
                        $req = $.getJSON(url, params);

                        // If the request is successful, update the form fields
                        $req.done(function(response) {
                            $resultsContainer.html(response.data);
                            // Initialise any newJS fields
                            initFields($resultsContainer);
                        });
                    } else {
                        // Clear div
                        $resultsContainer.html('');
                    }

                }else{

                    // Just show / hide
                    me.showHide($target, $resultsContainer);


                }
            });

            // If any fields that have required params change we need to get dat again
            $target.find('.js-dependant-block-extra-field').each(function(){
                $(this).change(function(){
                    if($controlField.val()){
                        $controlField.trigger('dependant:change');
                    }
                });
            });

            // Page load trigger
            // allows data-attribute and data-attribute="true"  otherwise returns false
            if (!checkDataAttr($controlField, 'data-depends-no-init', true)) {
                $controlField.trigger('dependant:change');
            }


        });

    },
    toggleFieldStatus($target, enable){
        if(enable){
            $target.find('input, textarea, button, select').prop('disabled', false);
        }else{
            $target.find('input, textarea, button, select').prop('disabled', true);
        }
    },
    showHide: function($target, $resultsContainer){
        var me = setDependantblocks;

        // Hide all blocks
        $resultsContainer.find('.js-dependant-block-target-item').addClass('hidden');

        // Disable all fields within blocks
        $resultsContainer.find('.js-dependant-block-target-item').each(function(){
            me.toggleFieldStatus($(this));
        });

        // array of blocks to show
        var showArray = [];

        $target.find('.js-dependant-block-field').each(function(){

            var $field = $(this);

            // Find the blocks I need to show
            var targetItemText = $field.find('option:selected:first').data('dependant-block-target');

            // Checkboxes need to be treated differently
            if($field.is(':checkbox') || $field.is(':radio')){
               
                if($field.prop('checked') === true){
                    targetItemText = $field.data('dependant-block-target');
                }
                
            }

            // We need to build an array of items to show (in case there are multiples)
            if(targetItemText){
                if(targetItemText.indexOf(',') !== -1){
                    // targetItemText is a comma seperated string, split it into an array
                    var targetItemTextArr = targetItemText.split(',');
                    targetItemTextArr.forEach(function (item) {
                        showArray.push($resultsContainer.find('[data-depends-block-self="'+ item +'"]'));
                    });
                }else{
                    // targetItemText is just a single item
                    showArray.push($resultsContainer.find('[data-depends-block-self="'+ targetItemText +'"]'));
                }

                
            }else{

               // Field has been deselected - might have a default block data attribute
                var defaultText = $field.data('dependant-block-default');
                if(defaultText){
                    showArray.push($resultsContainer.find('[data-depends-block-self="'+ defaultText +'"]'));
                }

            }

        });

        // Show Block
        for (var i=0; i < showArray.length; i++) {
            // enable all fields within
            me.toggleFieldStatus(showArray[i], true);
            // init summernote
            if(!showArray[i].find('.note-editor').length){
                showArray[i].find('.js-wysiwyg').each(function () {
                    setSummernote.init($(this));
                });
            }
            // show block
            showArray[i].removeClass('hidden');;
        }

    },
    destroy: function () {
    }
};
